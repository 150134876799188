import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import classes from "./PlayerInteractionsDialogs.module.css";
import {
    postPlayerInteraction,
    patchPlayerInteraction,
} from "../store/players-actions";
import TextInput from "../controls/TextInput";
import DatePicker from "../controls/DatePicker";
import {
    DialogControl,
    PostModalDialog,
} from "../controls/dialogs/ModalDialog";
import { usePlayerInteractionTextAnalysis } from "../hooks/use-playerinteraction-text-analysis";
import OptionDropdown from "../controls/OptionDropdown";
import OptionRadioBar from "../controls/OptionRadioBar";
import Toggle from "../controls/buttons/Toggle";
import PlayerSearchInput from "../controls/search-inputs/PlayerSearchInput";
import UserSearchInput from "../controls/search-inputs/UserSearchInput";
import { getCurrentDateString } from "../utils";

import { mp_track } from "../mixpanel";

export const player_interactions_mp_track = (
    event,
    player,
    properties = null
) => {
    if (player) {
        properties = {
            ...properties,
            player_id: player.id,
            player_name: player.known_name,
        };
    }

    mp_track(event, properties, "player_interactions");
};

const TextOption = ({ optionValue }) => {
    return <div className={classes.text_option}>{optionValue?.label}</div>;
};

const interactionTones = [
    { id: "positive", label: "Positive" },
    { id: "neutral", label: "Neutral" },
    { id: "negative", label: "Negative" },
];

export const PlayerInteractionDialog = ({
    player,
    playersPool,
    usersPool,
    interaction,
    isOpen,
    onCloseHandler,
    interactionTypes,
}) => {
    const dispatch = useDispatch();
    const { textAnalysis } = usePlayerInteractionTextAnalysis();

    const [interactionPlayer, setInteractionPlayer] = useState(player);
    const [interactionOwner, setInteractionOwner] = useState(null);
    const [interactionText, setInteractionText] = useState("");
    const [interactionDate, setInteractionDate] = useState(
        getCurrentDateString()
    );
    const [interactionTypeId, setInteractionTypeId] = useState(null);
    const [interactionType, setInteractionType] = useState(null);
    const [interactionTone, setInteractionTone] = useState(null);
    const [interactionDuration, setInteractionDuration] = useState(null);
    const [
        interactionPlayerInstigatedFlag,
        setInteractionPlayerInstigatedFlag,
    ] = useState(false);

    const [isTextModified, setIsTextModified] = useState(false);
    const [isToneModified, setIsToneModified] = useState(false);
    const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);
    const [isPostDisabled, setIsPostDisabled] = useState(true);
    const [lastAnalyzedText, setLastAnalyzedText] = useState("");

    const lastAnalyzedTextRef = useRef("");
    const toneManuallySetRef = useRef(false);
    const typeManuallySetRef = useRef(false);

    const detectTextAnalysis = useRef(
        debounce(async (text) => {
            if (
                (toneManuallySetRef.current && typeManuallySetRef.current) ||
                text === lastAnalyzedTextRef.current
            )
                return;

            setIsAnalysisLoading(true);
            try {
                const textAnalysisResult = await textAnalysis(text);
                if (!toneManuallySetRef.current) {
                    setInteractionTone({ id: textAnalysisResult.tone });
                    setIsToneModified(true);
                }
                if (!typeManuallySetRef.current) {
                    setInteractionTypeId(
                        textAnalysisResult.interaction_type.id
                    );
                }
                setLastAnalyzedText(text);
                lastAnalyzedTextRef.current = text;
            } catch (error) {
                console.error("Error detecting text analysis:", error);
            } finally {
                setIsAnalysisLoading(false);
            }
        }, 1000)
    );

    const debouncedDetectTextAnalysis = useCallback((text) => {
        detectTextAnalysis.current(text);
    }, []);

    useEffect(() => {
        lastAnalyzedTextRef.current = lastAnalyzedText;
    }, [lastAnalyzedText]);

    useEffect(() => {
        if (isOpen && player) {
            setInteractionPlayer(player);
        }
    }, [player, isOpen]);

    useEffect(() => {
        if (interactionTypeId) {
            setInteractionType(
                interactionTypes.find((st) => st.id === interactionTypeId)
            );
        }
    }, [interactionTypeId, interactionTypes]);

    useEffect(() => {
        const shouldDisable = false;
        setIsPostDisabled(shouldDisable);
    }, []);

    const interactionDurationOptions = useMemo(() => {
        return [
            { id: 1, duration: 15, label: "15 min" },
            { id: 2, duration: 30, label: "30 min" },
            { id: 3, duration: 45, label: "45 min" },
            { id: 4, duration: 60, label: "1 hr" },
        ];
    }, []);

    useEffect(() => {
        if (isOpen) {
            setInteractionOwner(null);
            setIsToneModified(false);
            toneManuallySetRef.current = false;
            typeManuallySetRef.current = false;

            if (interaction) {
                setInteractionText(interaction.text);
                setInteractionDate(interaction.date);
                setInteractionTypeId(
                    interaction.interaction_type.id || interactionTypes[0].id
                );
                setInteractionTone({ id: interaction.tone });
                setInteractionDuration(interaction.duration_minutes);
                setInteractionPlayerInstigatedFlag(
                    interaction.was_player_instigated
                );
                setIsTextModified(false);
            } else {
                // Set date to today by default
                setInteractionText("");
                setInteractionDate(getCurrentDateString());
                setInteractionTypeId(
                    interactionTypes &&
                        interactionTypes.length > 0 &&
                        interactionTypes[0].id
                );
                setInteractionTone({ id: "neutral" });
                setInteractionDuration(interactionDurationOptions[0].duration);
                setInteractionPlayerInstigatedFlag(false);
                setIsTextModified(true); // Consider text as modified for new interactions
            }
        }
    }, [interaction, interactionTypes, interactionDurationOptions, isOpen]);

    // Function to handle cancel/close
    const handleClose = useCallback(() => {
        setInteractionOwner(null);
        setInteractionPlayer(null);
        setInteractionText("");
        setInteractionDuration(null);
        setInteractionPlayerInstigatedFlag(false);
        onCloseHandler && onCloseHandler();
    }, [onCloseHandler]);

    const handleCancel = () => {
        handleClose();
        player_interactions_mp_track(
            "Player Interaction Dialog Cancelled",
            player
        );
    };

    // Function to handle post
    const handlePost = useCallback(() => {
        const isNewInteraction = !interaction;

        if (isNewInteraction) {
            dispatch(
                postPlayerInteraction(
                    interactionPlayer.id,
                    interactionTypeId,
                    isToneModified ? interactionTone.id : null,
                    interactionDate,
                    interactionDuration,
                    interactionPlayerInstigatedFlag,
                    interactionText,
                    interactionOwner?.id
                )
            );
        } else {
            dispatch(
                patchPlayerInteraction(
                    interaction.id,
                    interactionTypeId,
                    isToneModified ? interactionTone.id : null,
                    interactionDate,
                    interactionDuration,
                    interactionPlayerInstigatedFlag,
                    isTextModified ? interactionText : null
                )
            );
        }

        handleClose();

        // Prepare mixpanel data
        const mixpanelData = {
            date: interactionDate,
            interaction_type_id: interactionTypeId,
            interaction_type: interactionType.label,
            duration: interactionDuration,
            player_instigated: interactionPlayerInstigatedFlag,
            interaction_tone: interactionTone.id,
        };

        // Only include text and auto detect flags in mixpanel data if they were modified or it's a new interaction
        if (isNewInteraction || isTextModified) {
            mixpanelData.text = interactionText;
            mixpanelData.tone_auto_detected = !toneManuallySetRef.current;
            mixpanelData.type_auto_detected = !typeManuallySetRef.current;
        }

        // Add owner information
        if (isNewInteraction) {
            mixpanelData.owner = interactionOwner?.label;
        } else {
            mixpanelData.owner = `${interaction?.owner.first_name} ${interaction?.owner.last_name}`;
            mixpanelData.interaction_id = interaction.id;
        }

        // Track the appropriate event
        player_interactions_mp_track(
            isNewInteraction
                ? "Create Player Interaction"
                : "Update Player Interaction",
            interactionPlayer,
            mixpanelData
        );
    }, [
        dispatch,
        interactionPlayer,
        interactionOwner,
        interactionText,
        interactionDate,
        interactionTypeId,
        interactionTone,
        interactionDuration,
        interactionPlayerInstigatedFlag,
        interactionType,
        interaction,
        handleClose,
        isTextModified,
        isToneModified,
    ]);

    useEffect(() => {
        if (
            interactionText.split(/\s+/).length >= 5 &&
            (!toneManuallySetRef.current || !typeManuallySetRef.current) &&
            !isAnalysisLoading
        ) {
            debouncedDetectTextAnalysis(interactionText);
        }
    }, [interactionText, debouncedDetectTextAnalysis, isAnalysisLoading]);

    const handleTextChange = (e) => {
        setInteractionText(e.target.value);
        setIsTextModified(true);
    };

    const handleTextBlur = () => {
        if (
            isTextModified &&
            (!toneManuallySetRef.current || !typeManuallySetRef.current)
        ) {
            debouncedDetectTextAnalysis(interactionText);
        }
    };

    const handleToneSelect = (option) => {
        setInteractionTone(option);
        setIsToneModified(true);
        toneManuallySetRef.current = true;
    };

    const handleTypeSelect = (option) => {
        setInteractionTypeId(option.id);
        typeManuallySetRef.current = true;
    };

    useEffect(() => {
        const shouldDisable =
            !interactionText.trim() ||
            !interactionDate.trim() ||
            isAnalysisLoading ||
            (interaction &&
                interaction.text === interactionText &&
                interaction.date === interactionDate &&
                interaction.duration_minutes === interactionDuration &&
                interaction.interaction_type.id === interactionTypeId &&
                interaction.tone === interactionTone.id &&
                interaction.was_player_instigated ===
                    interactionPlayerInstigatedFlag);

        setIsPostDisabled(shouldDisable);
    }, [
        interactionText,
        interactionDate,
        interactionDuration,
        interactionTypeId,
        interactionTone,
        interactionPlayerInstigatedFlag,
        interaction,
        isAnalysisLoading,
    ]);

    return (
        <PostModalDialog
            title={(interaction ? "Update" : "Add") + " Interaction"}
            isOpen={isOpen}
            handlePost={handlePost}
            handleCancel={handleCancel}
            isPostDisabled={isPostDisabled}
            postLabel={interaction ? "Save" : "Add"}
        >
            <>
                {player && (
                    <DialogControl label={"Player"}>
                        <div className={classes.read_only_control}>
                            {player.known_name}
                        </div>
                    </DialogControl>
                )}
                {playersPool && (
                    <DialogControl label={"Player"}>
                        <PlayerSearchInput
                            players={playersPool}
                            selectedPlayerId={interactionPlayer?.id}
                            onResultSelected={(player) => {
                                setInteractionPlayer(player);
                            }}
                        />
                    </DialogControl>
                )}
                <DialogControl label={"Date"}>
                    <DatePicker
                        id={"interaction-date"}
                        value={interactionDate}
                        handleDateChange={(date) => setInteractionDate(date)}
                    />
                </DialogControl>
                <DialogControl label={"Duration"}>
                    <OptionDropdown
                        options={interactionDurationOptions}
                        selectedOption={interactionDurationOptions.find(
                            (o) => o.duration === interactionDuration
                        )}
                        onSelect={(option) =>
                            setInteractionDuration(option.duration)
                        }
                        OptionComponent={TextOption}
                    />
                </DialogControl>
                <DialogControl label={"Player Instigated"}>
                    <Toggle
                        id={"interaction-player-instigated"}
                        isChecked={interactionPlayerInstigatedFlag}
                        onToggle={(e) =>
                            setInteractionPlayerInstigatedFlag(e.target.checked)
                        }
                    />
                </DialogControl>
                {usersPool && (
                    <DialogControl label={"Coach"}>
                        <UserSearchInput
                            users={usersPool}
                            selectedUserId={interactionOwner?.id}
                            onResultSelected={(user) => {
                                setInteractionOwner(user);
                            }}
                            placeHolderText={"Search coach..."}
                        />
                    </DialogControl>
                )}
                <DialogControl label={"Note"}>
                    <TextInput
                        id={"interaction-text"}
                        value={interactionText}
                        handleInputChange={handleTextChange}
                        handleBlur={handleTextBlur}
                        placeholder={"Enter interaction details"}
                        multiline={true}
                    />
                </DialogControl>
                <DialogControl label={"Type"}>
                    <OptionDropdown
                        options={interactionTypes}
                        selectedOption={interactionType}
                        onSelect={handleTypeSelect}
                        OptionComponent={TextOption}
                    />
                </DialogControl>
                <DialogControl label={"Tone"}>
                    <OptionRadioBar
                        options={interactionTones}
                        selectedOption={interactionTone}
                        onSelect={handleToneSelect}
                        OptionComponent={TextOption}
                        disabled={isAnalysisLoading}
                    />
                </DialogControl>
            </>
        </PostModalDialog>
    );
};
