import React, {
    useRef,
    useMemo,
} from "react";
import classes from "./ResponsiveContainer.module.css";
import useWindowDimensions from "../hooks/use-window-dimensions";

export const PlaceholderResponsiveContainerChild = ({ children }) => {
    return children;
};

const ResponsiveContainer = ({
    toggleWidth,
    children,
    useParentWidth = false,
}) => {
    const containerRef = useRef(null);

    const { widthClasses } = useWindowDimensions([
        { min: toggleWidth, max: Infinity },
    ]);

    const layout = useMemo(() => {
        return useParentWidth
            ? containerRef.current?.offsetWidth > toggleWidth
                ? "horizontal"
                : "vertical"
            : widthClasses[0].matches
            ? "horizontal"
            : "vertical";
    }, [widthClasses, useParentWidth, toggleWidth]);

    const clonedChildren = React.Children.map(children, (child) => {
        const extraProps = { responsiveContainerLayoutMode: layout }; // passing layout as a prop
        return React.cloneElement(child, extraProps);
    });

    return (
        <div
            ref={containerRef}
            className={`${classes.responsive_container} ${classes[layout]}`}
        >
            {clonedChildren}
        </div>
    );
};

export default ResponsiveContainer;
