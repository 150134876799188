import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useMatch, useNavigate, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { uiActions } from "../../store/ui-slice";
import useAuth from "../../hooks/use-auth";

import { fetchCompetitionActiveTeams } from "../../store/competitions-actions";

import classes from "./NavMenuPanel.module.css";
import TextInput from "../../controls/TextInput";
import SlideInPanel from "../../layout/SlideInPanel";
import SearchButton from "../../controls/buttons/SearchButton";
import PlayerSearchResultsList from "../../controls/PlayerSearchResultsList";

function LoaderLink({ to, children, onClose, onLinkClick }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const handleClick = (event) => {
        event.preventDefault(); // Prevent default navigation

        // Check if the target location is same as the current location
        if (location.pathname === to) {
            onClose && onClose(); // Close menu if onClose is defined
            return;
        }

        if (to !== "#") {
            dispatch(uiActions.showLoader()); // Dispatch show loader

            setTimeout(() => {
                navigate(to); // Navigate after delay
                onClose && onClose(); // Close menu if onClose is defined
            }, 100); // Set delay to 200ms
        }
        onLinkClick && onLinkClick(); // Call function passed from parent if it is defined
    };

    return (
        <Link to={to} onClick={handleClick}>
            {children}
        </Link>
    );
}

const NavMenuPanel = ({
    isOpen,
    onClose,
    selectedTeamId,
    competitionId = 1,
}) => {
    const { user, checkPermission } = useAuth();

    const canAccessTeamPerformancePages = checkPermission(
        "core.can_access_team_performance_pages"
    );
    const canAccessPlayerPerformancePages = checkPermission(
        "core.can_access_player_performance_pages"
    );
    const canAccessPlayerAvailabilityPages = checkPermission(
        "core.can_access_player_availability_pages"
    );
    const canAccessPlayerInteractionsPages = checkPermission(
        "core.can_access_player_interactions_pages"
    );
    const canAccessPlayerDevelopmentPages = checkPermission(
        "core.can_access_player_development_pages"
    );
    const canAccessScoutingBoards = checkPermission(
        "core.can_access_scouting_boards"
    );

    const dispatch = useDispatch();
    const location = useLocation();
    const active_teams = useSelector(
        (state) => state.competitions.activeTeams[competitionId]
    );
    const navigate = useNavigate();

    const [teamViewMode, setTeamViewMode] = useState("playerSeasonPerformance");
    const [teamViewModeActive, setTeamViewModeActive] = useState(false);

    const selectedTeam = active_teams?.find(
        (team) => team.id === (selectedTeamId ? selectedTeamId : 16)
    );

    useEffect(() => {
        if (location.pathname.includes("playerseasonperformance")) {
            setTeamViewMode("playerseasonperformance");
            setTeamViewModeActive(true);
        } else if (location.pathname.includes("playerimpact")) {
            setTeamViewMode("playerimpact");
            setTeamViewModeActive(true);
        } else if (location.pathname.includes("depthchart")) {
            setTeamViewMode("depthchart");
            setTeamViewModeActive(true);
        } else if (location.pathname.includes("rosteredit")) {
            setTeamViewMode("rosteredit");
            setTeamViewModeActive(true);
        } else if (location.pathname.includes("capreport")) {
            setTeamViewMode("capreport");
            setTeamViewModeActive(true);
        } else {
            setTeamViewModeActive(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (competitionId && !active_teams) {
            dispatch(fetchCompetitionActiveTeams(competitionId));
        }
    }, [competitionId, active_teams, dispatch]);

    const draft_match = useMatch(`/app/draft/`);
    const scoutingboards_match = useMatch(`/app/scoutingboards/`);
    const historicaltrends_match = useMatch(`/app/historicaltrends/`);

    function BaseLink({ to, children, onClose }) {
        return (
            <LoaderLink to={to} onClose={onClose}>
                {children}
            </LoaderLink>
        );
    }

    const [searchQuery, setSearchQuery] = useState("");
    const [results, setResults] = useState([]);

    const debouncedFetchResultsRef = useRef(
        debounce((query) => {
            // The function inside here will now handle the asynchronous logic.
            const fetchData = async () => {
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_HABSHUB_API_BASE_URL}/hockey_analytics/search/players?query=${query}`
                    );
                    if (!response.ok) {
                        throw new Error("Failed to fetch search results");
                    }
                    const data = await response.json();
                    setResults(data || []);
                } catch (error) {
                    console.error("Failed to fetch search results:", error);
                }
            };

            // Call the async function.
            fetchData();
        }, 300)
    );

    // Effect for API call based on search query
    useEffect(() => {
        if (searchQuery.length >= 3) {
            debouncedFetchResultsRef.current(searchQuery);
        } else {
            setResults([]); // Clear results if under 3 characters
        }
    }, [searchQuery]); // Only recreate the effect if searchQuery changes

    // Cleanup the debounced function on component unmount
    useEffect(() => {
        const debouncedFetchResults = debouncedFetchResultsRef.current;
        return () => {
            debouncedFetchResults.cancel();
        };
    }, []);

    // Handle search input changes
    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    // Handle search submission
    const handleSearch = () => {
        if (searchQuery.trim()) {
            navigate(
                `/app/playersearch?query=${encodeURIComponent(searchQuery)}`
            );
            onClose();
        }
    };
    const handleSearchSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission
        handleSearch();
    };

    // Function to navigate to the individual player's page or the search page
    const handleResultSelect = (playerId) => {
        navigate(`/app/players/${playerId}/performance/`);
        onClose();
    };

    // Clear the search query when the nav menu is closed or opened
    useEffect(() => {
        setSearchQuery("");
        setResults([]);
    }, [isOpen]);

    // The search box JSX
    const searchBox = (
        <div className={classes.nav_section}>
            <div className={classes.nav_section_items_group}>
                <form
                    onSubmit={handleSearchSubmit}
                    className={classes.search_form}
                >
                    <TextInput
                        id={"search"}
                        placeholder={"Search Players"}
                        value={searchQuery}
                        handleInputChange={handleSearchChange}
                        mode={"dark"}
                    />
                    <SearchButton
                        type="submit"
                        className={classes.search_button}
                        disabled={!searchQuery}
                    />
                </form>
            </div>
        </div>
    );

    return (
        <SlideInPanel
            isOpen={isOpen}
            onClose={onClose}
            targetWidth={300}
            side="left"
            showCloseButton={false}
            noPadding={true}
            bgColor="var(--tw-slate-950)"
        >
            <div className={classes.nav_menu_panel}>
                {canAccessPlayerPerformancePages && (
                    <>
                        {searchBox}
                        {results && results.length > 0 && (
                            <div className={classes.search_results_dropdown}>
                                <PlayerSearchResultsList
                                    query={searchQuery.trim()}
                                    searchResults={results}
                                    maxListLength={5}
                                    onResultSelected={handleResultSelect}
                                    onViewAllSelected={handleSearch}
                                />
                            </div>
                        )}
                    </>
                )}
                {canAccessScoutingBoards && (
                    <div className={classes.nav_section}>
                        <div className={classes.nav_section_title}>
                            Player Scouting
                        </div>
                        <div className={classes.nav_section_items_group}>
                            <div className={classes.nav_subsection_items_group}>
                                <div
                                    className={`${classes.nav_item} ${
                                        draft_match ? classes.selected : ""
                                    }`}
                                >
                                    <BaseLink
                                        to={`/app/draft/`}
                                        onClose={onClose}
                                    >
                                        Draft
                                    </BaseLink>
                                </div>
                                <div
                                    className={`${classes.nav_item} ${
                                        scoutingboards_match
                                            ? classes.selected
                                            : ""
                                    }`}
                                >
                                    <BaseLink
                                        to={`/app/scoutingboards/`}
                                        onClose={onClose}
                                    >
                                        Boards
                                    </BaseLink>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {(canAccessPlayerAvailabilityPages ||
                    canAccessPlayerInteractionsPages ||
                    canAccessPlayerDevelopmentPages) && (
                    <div className={classes.nav_section}>
                        <div className={classes.nav_section_title}>
                            Team Operations
                        </div>
                        <div
                            className={
                                classes.nav_section_items_group +
                                " " +
                                classes.nav_subsection_items_group
                            }
                        >
                            {canAccessPlayerAvailabilityPages && (
                                <div
                                    className={`${classes.nav_item} ${
                                        location.pathname ===
                                        "/app/playeravailability/16"
                                            ? classes.selected
                                            : ""
                                    }`}
                                >
                                    <BaseLink
                                        to={`/app/playeravailability/16`}
                                        onClose={onClose}
                                    >
                                        NHL Availability
                                    </BaseLink>
                                </div>
                            )}
                            {canAccessPlayerInteractionsPages && (
                                <div
                                    className={`${classes.nav_item} ${
                                        location.pathname ===
                                        "/app/playerinteractions/16"
                                            ? classes.selected
                                            : ""
                                    }`}
                                >
                                    <BaseLink
                                        to={`/app/playerinteractions/16`}
                                        onClose={onClose}
                                    >
                                        Interactions
                                    </BaseLink>
                                </div>
                            )}
                            {canAccessPlayerDevelopmentPages && (
                                <div
                                    className={`${classes.nav_item} ${
                                        location.pathname ===
                                        "/app/playerdevelopment/16"
                                            ? classes.selected
                                            : ""
                                    }`}
                                >
                                    <BaseLink
                                        to={`/app/playerdevelopment/16`}
                                        onClose={onClose}
                                    >
                                        Development
                                    </BaseLink>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {(canAccessPlayerPerformancePages ||
                    canAccessTeamPerformancePages) &&
                    selectedTeam && (
                        <div className={classes.nav_section}>
                            <div className={classes.nav_section_title}>
                                {selectedTeam.display_name}
                            </div>
                            <div
                                className={
                                    classes.nav_section_items_group +
                                    " " +
                                    classes.nav_subsection_items_group
                                }
                            >
                                {canAccessPlayerPerformancePages && (
                                    <div
                                        className={`${classes.nav_item} ${
                                            selectedTeamId && teamViewModeActive
                                                ? classes.selected
                                                : ""
                                        }`}
                                    >
                                        <LoaderLink
                                            to={
                                                selectedTeamId &&
                                                teamViewModeActive
                                                    ? location.pathname
                                                    : `/app/${teamViewMode}/${selectedTeam.id}`
                                            }
                                            onClose={onClose}
                                            onLinkClick={() =>
                                                navigate(
                                                    `/app/${teamViewMode}/${selectedTeam.id}`
                                                )
                                            }
                                        >
                                            Team Roster
                                        </LoaderLink>
                                    </div>
                                )}
                                {canAccessTeamPerformancePages && (
                                    <div
                                        className={`${classes.nav_item} ${
                                            selectedTeamId &&
                                            location.pathname.includes(
                                                "prescout"
                                            )
                                                ? classes.selected
                                                : ""
                                        }`}
                                    >
                                        <LoaderLink
                                            to={
                                                selectedTeamId &&
                                                location.pathname.includes(
                                                    "prescout"
                                                )
                                                    ? location.pathname
                                                    : `/app/prescout/${selectedTeam.id}`
                                            }
                                            onClose={onClose}
                                            onLinkClick={() =>
                                                navigate(
                                                    `/app/prescout/${selectedTeam.id}`
                                                )
                                            }
                                        >
                                            Pre Scout
                                        </LoaderLink>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                {canAccessTeamPerformancePages && (
                    <div className={classes.nav_section}>
                        <div className={classes.nav_section_title}>League</div>
                        <div
                            className={
                                classes.nav_section_items_group +
                                " " +
                                classes.nav_subsection_items_group
                            }
                        >
                            <div className={classes.nav_item}>
                                <a
                                    href={
                                        process.env
                                            .REACT_APP_HABSHUB_WEB_BASE_URL +
                                        "dashboards/standings/nhl"
                                    }
                                >
                                    Scoring Chances
                                </a>
                            </div>
                            {user && user.is_staff && (
                                <div
                                    className={`${classes.nav_item} ${
                                        historicaltrends_match
                                            ? classes.selected
                                            : ""
                                    }`}
                                >
                                    <BaseLink
                                        to={`/app/historicaltrends/`}
                                        onClose={onClose}
                                    >
                                        Historical Trends
                                    </BaseLink>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className={classes.nav_item}>
                    <a
                        href={
                            process.env.REACT_APP_HABSHUB_WEB_BASE_URL +
                            "accounts/logout/"
                        }
                    >
                        Logout
                    </a>
                </div>
            </div>
        </SlideInPanel>
    );
};

export default NavMenuPanel;
