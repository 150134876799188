import { useState, useEffect, useCallback } from "react";
import OptionDropdown from "./OptionDropdown";
import { useDispatch } from "react-redux";
import useAuth from "../hooks/use-auth";
import classes from "./RosterEditorVersionSelector.module.css";

import { fetchTeamPlayerboards } from "../store/playerboards-actions";

const VersionOption = ({ optionValue }) => (
    <div className={classes.version_option}>
        <span>{optionValue.label}</span>
    </div>
);

const RosterEditorVersionSelector = ({
    teamId,
    selectedVersionId,
    onVersionIdSelect,
}) => {
    const dispatch = useDispatch();
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const { user } = useAuth();

    const loadVersions = useCallback(async () => {
        try {
            const fetchedVersions = await dispatch(
                fetchTeamPlayerboards(teamId)
            );
            const allVersions = fetchedVersions
                .sort((a, b) => {
                    if (a.owner.last_name < b.owner.last_name) {
                        return -1;
                    }
                    if (a.owner.last_name > b.owner.last_name) {
                        return 1;
                    }
                    if (a.owner.first_name < b.owner.first_name) {
                        return -1;
                    }
                    if (a.owner.first_name > b.owner.first_name) {
                        return 1;
                    }
                    return 0;
                })
                .map((v) => ({
                    id: v.id,
                    owner: v.owner,
                    label: `${v.owner.first_name} ${v.owner.last_name}`,
                }));
            setVersions(allVersions);
            if (allVersions.length > 0) {
                // By default, select the version of the current user, otherwise select the first version
                const selectedVersion = allVersions.find((v) =>
                    selectedVersionId
                        ? v.id === selectedVersionId
                        : v.owner.email === user.email
                );
                setSelectedVersion(selectedVersion);
                onVersionIdSelect(selectedVersion?.id);
            }
        } catch (error) {
            console.error("Error fetching versions:", error);
        }
    }, [teamId, dispatch, user.email, onVersionIdSelect, selectedVersionId]);

    useEffect(() => {
        if (selectedVersionId) {
            const version = versions.find((v) => v.id === selectedVersionId);
            if (version) {
                setSelectedVersion(version);
            } else {
                loadVersions();
            }
        }
    }, [selectedVersionId, versions, loadVersions]);

    useEffect(() => {
        if (teamId) {
            loadVersions();
        }
    }, [teamId, loadVersions]);

    const handleVersionSelect = (version) => {
        setSelectedVersion(version);
        onVersionIdSelect(version.id);
    };

    return (
        <div className={classes.version_selector}>
            <OptionDropdown
                options={versions}
                selectedOption={selectedVersion}
                onSelect={handleVersionSelect}
                OptionComponent={VersionOption}
            />
        </div>
    );
};

export default RosterEditorVersionSelector;
