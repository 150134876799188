import { useState, useRef, useEffect } from "react";
import DroppableGridCell from "./DroppableGridCell";
import classes from "./PlayerGrid.module.css";

const InsertRowControl = ({ rowIndex, onInsertRow }) => {
    // row index is 0-based, so we add 1 to make it 1-based.
    const targetRow = rowIndex + 1;

    const [isVisible, setIsVisible] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const controlRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                controlRef.current &&
                !controlRef.current.contains(event.target)
            ) {
                setIsVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleAddRowClick = (e) => {
        e.stopPropagation();
        if (isVisible) {
            onInsertRow && onInsertRow(targetRow);
            setIsHighlighted(true);
            setTimeout(() => {
                setIsHighlighted(false);
                setIsVisible(false);
            }, 500); // 500 milliseconds for the highlight
        }
    };

    return (
        <div
            ref={controlRef}
            className={`${classes.insert_row_control} ${
                isVisible ? classes.visible : ""
            } ${isHighlighted ? classes.highlight : ""}`}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            onClick={() => setIsVisible(true)}
        >
            <div className={classes.insert_row_spacer}></div>
            <div
                className={classes.insert_row_button}
                onClick={handleAddRowClick}
            >
                <div className={classes.insert_row_button_label}>+</div>
            </div>
        </div>
    );
};

const RemoveRowControl = ({ rowIndex, onRemoveRow }) => {
    // row index is 0-based, so we add 1 to make it 1-based.
    const targetRow = rowIndex + 1;

    const [isVisible, setIsVisible] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const controlRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                controlRef.current &&
                !controlRef.current.contains(event.target)
            ) {
                setIsVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleRemoveRowClick = (e) => {
        e.stopPropagation();
        if (isVisible) {
            onRemoveRow && onRemoveRow(targetRow);
            setIsHighlighted(true);
            setTimeout(() => {
                setIsHighlighted(false);
                setIsVisible(false);
            }, 500); // 500 milliseconds for the highlight
        }
    };

    return (
        <div
            ref={controlRef}
            className={`${classes.remove_row_control} ${
                isVisible ? classes.visible : ""
            } ${isHighlighted ? classes.highlight : ""}`}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            onClick={() => setIsVisible(true)}
        >
            <div
                className={classes.remove_row_button}
                onClick={handleRemoveRowClick}
            >
                <div className={classes.remove_row_button_label}>x</div>
            </div>
        </div>
    );
};

const PlayerGridRow = ({
    refId,
    rowIndex,
    row,
    player_layout_positions,
    updatesAllowed,
    onInsertRow,
    onRemoveRow,
    rowCountersGroupSize,
    row_count_labels,
    ...cellProps
}) => {
    return (
        <div className={classes.playergrid_row}>
            {player_layout_positions.map((position) => (
                <DroppableGridCell
                    refId={refId}
                    key={`${refId}-${rowIndex + 1}-${position}`}
                    rowIndex={rowIndex}
                    position={position}
                    player={row[position]}
                    updatesAllowed={updatesAllowed}
                    {...cellProps}
                />
            ))}
            {updatesAllowed && (
                <>
                    {onInsertRow && (
                        <InsertRowControl
                            rowIndex={rowIndex}
                            onInsertRow={onInsertRow}
                        />
                    )}
                    {onRemoveRow && !Object.values(row).some((v) => v) && (
                        <RemoveRowControl
                            rowIndex={rowIndex}
                            onRemoveRow={onRemoveRow}
                        />
                    )}
                </>
            )}
            <div
                className={
                    classes.row_count_container +
                    (rowCountersGroupSize ? "" : " " + classes.no_count)
                }
            >
                <div className={classes.row_count}>
                    {row_count_labels?.[rowIndex]}
                </div>
            </div>
        </div>
    );
};

export default PlayerGridRow;
