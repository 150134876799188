import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { playerDevelopmentOptionsActions } from "./playerdevelopmentoptions-slice";

let ongoingRequests = {
    fetchPlayerDevelopmentPriorityOutcomes: false,
    fetchPlayerInteractionTypes: false,
};

export const fetchPlayerDevelopmentPriorityOutcomes = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerDevelopmentPriorityOutcomes) {
            ongoingRequests.fetchPlayerDevelopmentPriorityOutcomes = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerdevelopmentpriorityoutcomes/",
                "Could not fetch player development priority outcomes data!"
            )
                .then((playerDevelopmentPriorityOutcomesData) => {
                    dispatch(
                        playerDevelopmentOptionsActions.updatePlayerDevelopmentPriorityOutcomes(
                            playerDevelopmentPriorityOutcomesData
                        )
                    );
                    ongoingRequests.fetchPlayerDevelopmentPriorityOutcomes = false;
                    return playerDevelopmentPriorityOutcomesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching all player development priority outcomes data failed!",
                        })
                    );
                    ongoingRequests.fetchPlayerDevelopmentPriorityOutcomes = false;
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerDevelopmentPriorityOutcomes;
    };
};

export const fetchPlayerInteractionTypes = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerInteractionTypes) {
            ongoingRequests.fetchPlayerInteractionTypes = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerinteractiontypes/",
                "Could not fetch player development session types data!"
            )
                .then((playerInteractionTypesData) => {
                    dispatch(
                        playerDevelopmentOptionsActions.updatePlayerInteractionTypes(
                            playerInteractionTypesData
                        )
                    );
                    ongoingRequests.fetchPlayerInteractionTypes = false;
                    return playerInteractionTypesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching all player development session types data failed!",
                        })
                    );
                    ongoingRequests.fetchPlayerInteractionTypes = false;
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerInteractionTypes;
    };
};
