import { LegendSection, LegendItem } from "../../../controls/Legend";
import { ToneIndicator } from "../../indicators";
import player_development_classes from "../../../app-pages/TeamPlayerDevelopment.module.css";

const PlayerInteractionsTileSection = () => {
    return (
        <LegendSection
            title="Player Interactions Tile"
            description="The player interactions tiles gives a quick glance assessment of the 5 most recent interactions for the player, as well as the total number interactions for the season."
        >
            <LegendItem
                symbol={<ToneIndicator label={"XX"} tone={"positive"} />}
                description="The recent interaction had a positive tone. The initials of the coach that had the interaction are shown."
            />
            <LegendItem
                symbol={<ToneIndicator label={"XX"} tone={"neutral"} />}
                description="The recent interaction had a neutral tone."
            />
            <LegendItem
                symbol={<ToneIndicator label={"XX"} tone={"negative"} />}
                description="The recent interaction had a negative tone."
            />
            <LegendItem
                symbol={
                    <div
                        className={
                            player_development_classes.season_sessions_count
                        }
                    >
                        xd
                    </div>
                }
                description="The most recent personal interaction with the player was x days ago. The value can be in minutes, hours, days or weeks."
            />
            <LegendItem
                symbol={
                    <div
                        className={
                            player_development_classes.season_sessions_count
                        }
                    >
                        #
                    </div>
                }
                description="The total count of personal interactions the player has had with coaching staff members this season."
            />
        </LegendSection>
    );
};

export default PlayerInteractionsTileSection;
