import { useState, useEffect, useCallback, useRef } from "react";

const useElementDimensions = (widthRanges = []) => {
    const elementRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [widthClasses, setWidthClasses] = useState([]);

    const getWidthClasses = useCallback(
        (width) => {
            return widthRanges.map((range) => ({
                min: range.min,
                max: range.max,
                matches:
                    width >= (range.min || 0) &&
                    width <= (range.max || Infinity),
            }));
        },
        [widthRanges]
    );

    useEffect(() => {
        if (!elementRef.current) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;
                setDimensions({ width, height });
                setWidthClasses(getWidthClasses(width));
            }
        });

        resizeObserver.observe(elementRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [getWidthClasses]);

    return { elementRef, dimensions, widthClasses };
};

export default useElementDimensions;
