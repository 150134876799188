import { useState, useEffect, useCallback } from "react";
import useHttp from "./use-http";
import { useDispatch } from "react-redux";
import { fetchSystemConfiguration, fetchUserSettings } from "../store/ui-slice";
import { useLocation } from "react-router-dom";

const COOLDOWN_PERIOD = 30000; // 30 seconds

const globalState = {
    user: null,
    authChecked: false,
    isCheckingAuth: false,
    lastCheckTime: 0,
    checkAuthPromise: null,
};

function useAuth() {
    const dispatch = useDispatch();
    const { error, sendRequest } = useHttp();
    const [, forceRender] = useState({});
    const location = useLocation();

    const isAuthenticated = !error && globalState.user !== null;

    const performAuthCheck = useCallback(
        (tempToken = null) => {
            return new Promise((resolve) => {
                const endpoint = tempToken
                    ? `${process.env.REACT_APP_HABSHUB_API_BASE_URL}authenticate-temp/${tempToken}/`
                    : `${process.env.REACT_APP_HABSHUB_API_BASE_URL}users/me/`;

                sendRequest({ url: endpoint }, (userData, err) => {
                    if (err) {
                        resolve(false);
                        return;
                    }

                    // Redirect to the same URL without the temp_token parameter
                    if (tempToken) {
                        const newSearchParams = new URLSearchParams(
                            location.search
                        );
                        newSearchParams.delete("temp_token");

                        const newSearch = newSearchParams.toString();
                        const newPath =
                            location.pathname +
                            (newSearch ? `?${newSearch}` : "");
                        window.location.href = newPath;
                    } else {
                        globalState.user = userData;
                        globalState.authChecked = true;
                        globalState.isCheckingAuth = false;
                        globalState.checkAuthPromise = null;
                        forceRender({});

                        dispatch(fetchSystemConfiguration());
                        dispatch(fetchUserSettings());
                    }

                    resolve(true);
                });
            });
        },
        [sendRequest, dispatch, location]
    );

    const checkAuth = useCallback(() => {
        const currentTime = Date.now();
        if (
            globalState.isCheckingAuth ||
            (currentTime - globalState.lastCheckTime < COOLDOWN_PERIOD &&
                globalState.user)
        ) {
            return globalState.checkAuthPromise || Promise.resolve(true);
        }

        globalState.isCheckingAuth = true;
        globalState.lastCheckTime = currentTime;

        const searchParams = new URLSearchParams(location.search);
        const tempToken = searchParams.get("temp_token");

        globalState.checkAuthPromise = new Promise((resolve) => {
            performAuthCheck(tempToken).then((isAuthenticated) => {
                if (!isAuthenticated) {
                    window.location.href = `${process.env.REACT_APP_HABSHUB_WEB_BASE_URL}accounts/login/`;
                    resolve(false);
                } else {
                    resolve(true);
                }
            });
        });

        return globalState.checkAuthPromise;
    }, [location.search, performAuthCheck]);

    useEffect(() => {
        const currentTime = Date.now();
        if (
            (!globalState.authChecked ||
                currentTime - globalState.lastCheckTime > COOLDOWN_PERIOD) &&
            !globalState.isCheckingAuth
        ) {
            checkAuth();
        }
    }, [checkAuth]);

    const checkPermission = useCallback((permission) => {
        if (globalState.user) {
            return (
                globalState.user.permissions.includes(permission) ||
                globalState.user.is_superuser ||
                globalState.user.is_staff
            );
        }
        return false;
    }, []);

    return {
        isAuthenticated,
        isLoading: globalState.isCheckingAuth,
        authChecked: globalState.authChecked,
        user: globalState.user,
        checkPermission,
    };
}

export default useAuth;
