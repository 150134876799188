import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import classes from "./ViewNavigator.module.css";

const defaultBreakpoints = {
    ultraWide: 1600,
    desktop: 1024,
    tablet: 768,
    largePhone: 450,
};

const ViewNavigator = ({
    views,
    preserveViews = false,
    breakpoints = defaultBreakpoints,
    className = "",
    navClassName = "",
}) => {
    const [selectedViewId, setSelectedViewId] = useState(views[0]?.id);

    const isUltraWide = useMediaQuery({ minWidth: breakpoints.ultraWide });
    //const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop });
    //const isTablet = useMediaQuery({ minWidth: breakpoints.tablet });
    const isLargePhone = useMediaQuery({ minWidth: breakpoints.largePhone });
    const isPortrait = useMediaQuery({ orientation: "portrait" });

    const useVerticalNav = !isUltraWide && !isPortrait;
    const useCompactLabels = !isLargePhone;

    const renderViews = () => {
        if (preserveViews) {
            return views.map((view) => (
                <div
                    key={view.id}
                    className={classes.view}
                    style={{
                        display: selectedViewId === view.id ? "block" : "none",
                    }}
                    role="tabpanel"
                    aria-labelledby={`tab-${view.id}`}
                >
                    {view.component}
                </div>
            ));
        }

        const selectedView = views.find((view) => view.id === selectedViewId);
        return (
            <div
                className={classes.view}
                role="tabpanel"
                aria-labelledby={`tab-${selectedViewId}`}
            >
                {selectedView?.component}
            </div>
        );
    };

    return (
        <div
            className={`
        ${classes.container}
        ${useVerticalNav ? classes.verticalLayout : classes.horizontalLayout}
        ${className}
      `}
        >
            <nav
                className={`
          ${classes.nav}
          ${useVerticalNav ? classes.verticalNav : classes.horizontalNav}
          ${navClassName}
        `}
                role="tablist"
            >
                {views.map((view) => (
                    <button
                        key={view.id}
                        id={`tab-${view.id}`}
                        onClick={() => setSelectedViewId(view.id)}
                        className={`
              ${classes.navItem}
              ${selectedViewId === view.id ? classes.selectedNavItem : ""}
              ${isUltraWide ? classes.ultraWideNavItem : ""}
            `}
                        role="tab"
                        aria-selected={selectedViewId === view.id}
                        aria-controls={`view-${view.id}`}
                    >
                        {useCompactLabels ? view.shortLabel : view.label}
                    </button>
                ))}
            </nav>
            <main className={classes.content}>{renderViews()}</main>
        </div>
    );
};

export default ViewNavigator;
