import classes from "./PlayerInjuryStatusAndNotesPanel.module.css";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import SlideInPanel from "../../layout/SlideInPanel";
import PlayerHeader from "../player-header/PlayerHeader";
import OverheadTitleSection from "../../layout/OverheadTitleSection";
import { fetchPlayerStatusCategories } from "../../store/playerstatuscategories-actions";
import {
    fetchPlayerStatusUpdates,
    postPlayerStatusUpdate,
    patchPlayerNote,
} from "../../store/players-actions";
import PlayerNote from "../PlayerNote";
import TextInput from "../../controls/TextInput";
import DatePicker from "../../controls/DatePicker";
import Toggle from "../../controls/buttons/Toggle";
import { StatusValueIndicator } from "../indicators";
import {
    DialogControl,
    PostModalDialog,
} from "../../controls/dialogs/ModalDialog";
import OptionDropdown from "../../controls/OptionDropdown";
import useWindowDimensions from "../../hooks/use-window-dimensions";
import { getCurrentDateString } from "../../utils";

import { mp_track } from "../../mixpanel";

const player_injury_status_and_notes_mp_track = (
    event,
    player,
    properties = null
) => {
    properties = {
        ...properties,
        player_id: player.id,
        player_name: player.known_name,
    };

    mp_track(event, properties, "player_status");
};

const StatusValueOption = ({ optionValue }) => {
    return (
        <div className={classes.status_value_option_container}>
            <StatusValueIndicator statusValue={optionValue} flipped={true} />
        </div>
    );
};

const EditNoteDialog = ({ player, note, setNote }) => {
    const dispatch = useDispatch();

    const [updateText, setUpdateText] = useState("");
    const [isPostDisabled, setIsPostDisabled] = useState(true);

    useEffect(() => {
        setUpdateText(note ? note.text : "");
    }, [note]);

    useEffect(() => {
        const shouldDisable = !updateText.trim() || updateText === note.text;
        setIsPostDisabled(shouldDisable);
    }, [note, updateText]);

    // Function to handle cancel/close
    const handleClose = useCallback(() => {
        setUpdateText("");
        setNote(null);
    }, [setNote]);

    const handleCancel = () => {
        handleClose();
        player_injury_status_and_notes_mp_track("Edit Note Cancelled", player, {
            note_id: note.id,
            category_id: note.status_category.id,
            category_name: note.status_category.name,
        });
    };

    // Function to handle post
    const handlePatch = useCallback(() => {
        dispatch(patchPlayerNote(note.id, updateText));

        handleClose();

        const mixpanelData = {
            note_id: note.id,
            category_id: note.status_category.id,
            category_name: note.status_category.name,
            text: updateText,
        };

        player_injury_status_and_notes_mp_track(
            "Post Note Edit",
            player,
            mixpanelData
        );
    }, [dispatch, handleClose, player, updateText, note]);

    return (
        <PostModalDialog
            title={"Edit " + (note && note.status_category.name) + " Note"}
            isOpen={note !== null}
            handlePost={handlePatch}
            handleCancel={handleCancel}
            isPostDisabled={isPostDisabled}
            postLabel={"Save"}
        >
            {/* Text box for note text */}
            <DialogControl label={"Note"}>
                <TextInput
                    value={updateText}
                    handleInputChange={(e) => setUpdateText(e.target.value)}
                    placeholder={"Enter note details"}
                    multiline={true}
                />
            </DialogControl>
        </PostModalDialog>
    );
};

const PostStatusUpdateDialog = ({
    player,
    selectedCategory,
    setSelectedCategory,
    latestUpdate,
}) => {
    const dispatch = useDispatch();

    const activeInjury =
        latestUpdate &&
        latestUpdate.injury &&
        !latestUpdate.injury.end_date &&
        latestUpdate.injury;
    const initialSelectedValue =
        (latestUpdate && latestUpdate.status_value) ||
        selectedCategory?.default_status_value ||
        null;
    const isMedicalUpdate = selectedCategory?.name === "Medical";

    const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
    const isOkToPlay = selectedValue && selectedValue.level === "ok";
    const [updateText, setUpdateText] = useState("");
    const [injuryDescription, setInjuryDescription] = useState(
        (activeInjury && activeInjury.description) || ""
    );
    const [injuryStartDate, setInjuryStartDate] = useState(
        (activeInjury && activeInjury.start_date) || ""
    );
    const [injuryEndDate, setInjuryEndDate] = useState(getCurrentDateString());
    const [isPostDisabled, setIsPostDisabled] = useState(true);
    const initialToggleState =
        latestUpdate && latestUpdate.status_value.level === "ok" ? false : true;
    const [isEndDateActive, setEndDateActive] = useState(false);
    const startDateDisabled =
        !activeInjury && isOkToPlay && !injuryDescription.trim();

    const areBothDatesEdited =
        activeInjury &&
        injuryStartDate !== activeInjury.start_date &&
        injuryEndDate;

    useEffect(() => {
        if (startDateDisabled) {
            // Clear if there is no previous injury AND OK to play or description is empty
            setInjuryStartDate("");
        } else {
            setInjuryStartDate(
                (activeInjury && activeInjury.start_date) ||
                    getCurrentDateString()
            ); // Set to latest update or today
        }
    }, [activeInjury, startDateDisabled]);

    useEffect(() => {
        setInjuryDescription((activeInjury && activeInjury.description) || "");
    }, [activeInjury]);

    useEffect(() => {
        setSelectedValue(initialSelectedValue);
    }, [initialSelectedValue]);

    useEffect(() => {
        const shouldDisable =
            !updateText.trim() ||
            (selectedCategory &&
                selectedCategory.uses_status_values &&
                !selectedValue) ||
            // Any situation where the status value is not ok to
            // play, and we are missing some injury details
            (isMedicalUpdate &&
                !isOkToPlay &&
                (!injuryDescription.trim() || !injuryStartDate)) ||
            // The current status is ok to play, and the injury content
            // is inconsistent. Note that we could also check whether
            // there is an activeInjury, and in this case you cannot
            // have the injury details all be empty. But the UI takes
            // care of that consistency already.
            (isMedicalUpdate &&
                isOkToPlay &&
                ((!injuryDescription.trim() && injuryStartDate) ||
                    (injuryDescription.trim() && !injuryStartDate)));
        setIsPostDisabled(shouldDisable);
    }, [
        updateText,
        injuryDescription,
        injuryStartDate,
        selectedValue,
        selectedCategory,
        isOkToPlay,
        isMedicalUpdate,
    ]);

    // Function to handle cancel/close
    const handleClose = useCallback(() => {
        setSelectedCategory(null);
        setInjuryDescription("");
        setInjuryStartDate("");
        setInjuryEndDate("");
        setEndDateActive(false);
        setUpdateText("");
    }, [setSelectedCategory]);

    const handleCancel = () => {
        handleClose();
        player_injury_status_and_notes_mp_track(
            "Post Player Status Update Cancelled",
            player,
            {
                category_id: selectedCategory.id,
                category_name: selectedCategory.name,
            }
        );
    };

    // Function to handle post
    const handlePost = useCallback(() => {
        dispatch(
            postPlayerStatusUpdate(
                player.id,
                selectedCategory.id,
                selectedCategory.uses_status_values && selectedValue.id,
                updateText,
                isMedicalUpdate &&
                    injuryDescription !== "" &&
                    injuryStartDate !== "" && {
                        description: injuryDescription,
                        start_date: injuryStartDate,
                        ...(activeInjury && { id: activeInjury.id }),
                        ...(activeInjury &&
                            isEndDateActive && {
                                end_date: injuryEndDate,
                            }),
                    }
            )
        );

        handleClose();

        const mixpanelData = {
            category_id: selectedCategory.id,
            category_name: selectedCategory.name,
            text: updateText,
        };

        if (selectedCategory.uses_status_values) {
            mixpanelData.status_value_id = selectedValue.id;
            mixpanelData.status_value_label = selectedValue.label;
        }

        player_injury_status_and_notes_mp_track(
            "Post Player Status Update",
            player,
            mixpanelData
        );
    }, [
        dispatch,
        handleClose,
        player,
        selectedCategory,
        selectedValue,
        updateText,
        isMedicalUpdate,
        activeInjury,
        injuryDescription,
        injuryStartDate,
        injuryEndDate,
        isEndDateActive,
    ]);

    return (
        <PostModalDialog
            title={
                "Post " +
                (selectedCategory && selectedCategory.name) +
                " Update"
            }
            isOpen={selectedCategory !== null}
            handlePost={handlePost}
            handleCancel={handleCancel}
            isPostDisabled={isPostDisabled}
        >
            {/* Status Value selection drop down, if applicable */}
            {selectedCategory && selectedCategory.uses_status_values && (
                <DialogControl label={"Status"}>
                    <OptionDropdown
                        options={selectedCategory.values}
                        onSelect={(option) => {
                            /*
                                                        The checkbox should only be active if the selected status value is "Ok to play".
                                                        Otherwise, it should be disabled and unchecked.
                                                        If the user sets the selected status value to "Ok to play" using the drop down,
                                                        the check box should become active. If the latestUpdate status value was "Ok to play",
                                                        it should default to unchecked.  Otherwise, if the latestUpdate status value was
                                                        anything else, it should default to checked.
                                                    */
                            setSelectedValue(option);
                            if (option.level === "ok") {
                                setEndDateActive(initialToggleState);

                                if (initialToggleState) {
                                    setInjuryEndDate(getCurrentDateString());
                                }
                            } else {
                                setEndDateActive(false);
                            }
                        }}
                        selectedOption={selectedValue}
                        OptionComponent={StatusValueOption}
                    />
                </DialogControl>
            )}

            {/* Medical Status Update Injury Options, if applicable */}
            {isMedicalUpdate && (
                <>
                    <div className={classes.spacer} />
                    <DialogControl label={"Injury"}>
                        <TextInput
                            id="injury-description"
                            value={injuryDescription}
                            handleInputChange={(e) =>
                                setInjuryDescription(e.target.value)
                            }
                            onBlur={() => {
                                if (!injuryDescription.trim()) {
                                    setInjuryDescription(
                                        (activeInjury &&
                                            activeInjury.description) ||
                                            ""
                                    );
                                }
                            }}
                            placeholder={
                                (activeInjury && activeInjury.description) ||
                                "New injury"
                            }
                        />
                    </DialogControl>
                    <div
                        className={
                            classes.post_update_multi_row +
                            (areBothDatesEdited ? " " + classes.warning : "")
                        }
                    >
                        <DialogControl label={"Start"}>
                            <DatePicker
                                value={injuryStartDate}
                                handleDateChange={(date) =>
                                    setInjuryStartDate(date)
                                }
                                onBlur={() => {
                                    if (!injuryStartDate) {
                                        setInjuryStartDate(
                                            (activeInjury &&
                                                activeInjury.start_date) ||
                                                (!activeInjury &&
                                                    injuryDescription.trim() &&
                                                    new Date()
                                                        .toISOString()
                                                        .split("T")[0]) ||
                                                ""
                                        );
                                    }
                                }}
                                readOnly={startDateDisabled}
                            />
                        </DialogControl>
                        {/* Conditionnally show the end date */}
                        {activeInjury && (
                            <DialogControl label={"End"}>
                                <div className={classes.date_toggle}>
                                    <Toggle
                                        id="end-date-toggle"
                                        isChecked={isEndDateActive}
                                        onToggle={(e) => {
                                            setEndDateActive(e.target.checked);

                                            // If the user is toggling the end date on, set the status to Ok to play
                                            // Otherwise, set it to the injury's status value
                                            setSelectedValue(
                                                e.target.checked
                                                    ? selectedCategory.values[0]
                                                    : latestUpdate.status_value
                                            );

                                            setInjuryEndDate(
                                                e.target.checked
                                                    ? new Date()
                                                          .toISOString()
                                                          .split("T")[0]
                                                    : null
                                            );
                                        }}
                                    />
                                </div>
                                <div className={classes.date_toggle_date}>
                                    {isEndDateActive && (
                                        <DatePicker
                                            value={injuryEndDate}
                                            handleDateChange={(date) =>
                                                setInjuryEndDate(date)
                                            }
                                        />
                                    )}
                                </div>
                            </DialogControl>
                        )}
                        <div
                            className={
                                classes.warning_text_container +
                                (areBothDatesEdited
                                    ? " " + classes.warning
                                    : "")
                            }
                        >
                            <div className={classes.warning_text}>
                                Both Start and End dates will be modified.
                            </div>
                        </div>
                    </div>
                    <div className={classes.spacer} />
                </>
            )}

            {/* Text box for update notes */}
            <DialogControl label={"Notes"}>
                <TextInput
                    id="update-text"
                    value={updateText}
                    handleInputChange={(e) => setUpdateText(e.target.value)}
                    placeholder={"Enter update details"}
                    multiline={true}
                />
            </DialogControl>
        </PostModalDialog>
    );
};

function PlayerInjuryStatusAndNotesPanel({
    playerId,
    competitionId,
    isOpen,
    onCloseHandler,
    targetWidthHandler,
}) {
    const dispatch = useDispatch();

    const wideContentWidth = 480;
    const narrowContentWidth = 370;
    const contentPaddingWidth = 20;

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );

    const { widthClasses } = useWindowDimensions([{ min: 880, max: Infinity }]);
    const [targetWidth, setTargetWidth] = useState(
        widthClasses[0]?.matches || false
            ? wideContentWidth + contentPaddingWidth
            : narrowContentWidth + contentPaddingWidth
    );

    useEffect(() => {
        if (isOpen) {
            targetWidthHandler && targetWidthHandler(targetWidth);
        } else {
            targetWidthHandler && targetWidthHandler(null);
        }
    }, [isOpen, targetWidth, targetWidthHandler]);

    useEffect(() => {
        const newWidth =
            widthClasses[0]?.matches || false
                ? wideContentWidth + contentPaddingWidth
                : narrowContentWidth + contentPaddingWidth;
        setTargetWidth(newWidth);
    }, [
        widthClasses,
        wideContentWidth,
        narrowContentWidth,
        contentPaddingWidth,
    ]);

    const [confirmedPlayerId, setConfirmedPlayerId] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [noteForEdit, setNoteForEdit] = useState(null);

    const player = useSelector(
        (state) => state.players.players[confirmedPlayerId]
    );

    const playerStatusCategories = useSelector(
        (state) => state.playerstatuscategories.categories
    );

    useEffect(() => {
        if (Object.keys(playerStatusCategories).length === 0) {
            dispatch(fetchPlayerStatusCategories());
        }
    }, [dispatch, playerStatusCategories]);

    const [playerChangeWait, setPlayerChangeWait] = useState(false);

    useEffect(() => {
        setPlayerChangeWait(true);
        dispatch(fetchPlayerStatusUpdates(playerId));
        setTimeout(() => {
            setConfirmedPlayerId(playerId);
            setPlayerChangeWait(false);
        }, 175);
    }, [playerId, dispatch]);

    const playerStatusUpdates = useSelector(
        (state) => state.players.statusupdates[confirmedPlayerId]
    );

    const latestPlayerStatusUpdateByCategory =
        playerStatusCategories && playerStatusUpdates
            ? Object.values(playerStatusCategories).reduce(
                  (accumulator, category) => {
                      const categoryStatusUpdates = playerStatusUpdates.filter(
                          (statusUpdate) =>
                              statusUpdate.status_category.id === category.id
                      );
                      const latestStatusUpdate = categoryStatusUpdates
                          ? categoryStatusUpdates[0]
                          : null;

                      if (latestStatusUpdate) {
                          accumulator[category.id] = latestStatusUpdate;
                      }

                      return accumulator;
                  },
                  {}
              )
            : null;

    useEffect(() => {
        if (player) {
            player_injury_status_and_notes_mp_track(
                "View Player Injury Status and Notes",
                player
            );
        }
    }, [player]);

    const onClose = () => {
        player_injury_status_and_notes_mp_track(
            "Player Injury Status and Notes Closed",
            player
        );
        onCloseHandler();
    };

    const isLoading = playerChangeWait;

    return (
        <>
            <PostStatusUpdateDialog
                player={player}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                latestUpdate={
                    selectedCategory &&
                    latestPlayerStatusUpdateByCategory &&
                    latestPlayerStatusUpdateByCategory[selectedCategory.id]
                }
            />
            <EditNoteDialog
                player={player}
                note={noteForEdit}
                setNote={setNoteForEdit}
            />
            <SlideInPanel
                isOpen={isOpen}
                isLoading={isLoading}
                onClose={onClose}
                targetWidth={targetWidth}
                side="right"
            >
                {confirmedPlayerId && (
                    <div className={classes.slide_in_content}>
                        <div className={classes.player_header_container}>
                            <PlayerHeader
                                player={{
                                    ...player,
                                }}
                                narrowLayout={targetWidth < 700}
                                showContractDetails={false}
                                performanceViewContext={{
                                    competitionId: competitionId,
                                    seasonId: currentSeasonId,
                                }}
                            />
                        </div>
                        <OverheadTitleSection
                            title={"Latest Updates"}
                        ></OverheadTitleSection>
                        <div className={classes.status_updates_container}>
                            {playerStatusCategories &&
                                Object.values(playerStatusCategories)
                                    .sort((a, b) => a.order - b.order)
                                    .map((category) => (
                                        <div
                                            key={category.id}
                                            className={
                                                classes.status_update_category_container +
                                                (category.can_post_updates
                                                    ? " " + classes.clickable
                                                    : "")
                                            }
                                            onClick={
                                                category.can_post_updates
                                                    ? () => {
                                                          setSelectedCategory(
                                                              category
                                                          );
                                                          player_injury_status_and_notes_mp_track(
                                                              "Open Post Player Status Update Dialog",
                                                              player,
                                                              {
                                                                  category_id:
                                                                      category.id,
                                                                  category_name:
                                                                      category.name,
                                                              }
                                                          );
                                                      }
                                                    : undefined
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.status_update_category_content_container
                                                }
                                            >
                                                <PlayerNote
                                                    note={
                                                        latestPlayerStatusUpdateByCategory &&
                                                        latestPlayerStatusUpdateByCategory[
                                                            category.id
                                                        ]
                                                    }
                                                    category={category}
                                                    isStatusUpdate={true}
                                                    isEmptyUpdate={
                                                        !latestPlayerStatusUpdateByCategory ||
                                                        !latestPlayerStatusUpdateByCategory[
                                                            category.id
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))}
                        </div>
                        <OverheadTitleSection
                            title={"Updates and Notes Log"}
                        ></OverheadTitleSection>
                        <div className={classes.status_updates_container}>
                            {(!playerStatusUpdates ||
                                playerStatusUpdates.length === 0) && (
                                <div className={classes.empty_log}>
                                    No updates.
                                </div>
                            )}
                            {playerStatusUpdates &&
                                playerStatusUpdates.map((statusUpdate) => {
                                    return (
                                        <div
                                            key={statusUpdate.id}
                                            className={classes.note_container}
                                        >
                                            <div
                                                className={classes.note_content}
                                            >
                                                <PlayerNote
                                                    note={statusUpdate}
                                                    category={
                                                        statusUpdate.status_category
                                                    }
                                                    editClickHandler={() => {
                                                        setNoteForEdit(
                                                            statusUpdate
                                                        );
                                                        player_injury_status_and_notes_mp_track(
                                                            "Open Edit Player Note Dialog",
                                                            player,
                                                            {
                                                                note_id:
                                                                    statusUpdate.id,
                                                                category_id:
                                                                    statusUpdate
                                                                        .status_category
                                                                        .id,
                                                                category_name:
                                                                    statusUpdate
                                                                        .status_category
                                                                        .name,
                                                            }
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </SlideInPanel>
        </>
    );
}

export default PlayerInjuryStatusAndNotesPanel;
