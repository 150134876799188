import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
    name: "users",
    initialState: {
        coaches: [],
    },
    reducers: {
        updateCoaches(state, action) {
            const coachesMap = new Map(
                state.coaches.map((coach) => [coach.id, coach])
            );

            action.payload.forEach((coach) => {
                coachesMap.set(coach.id, coach);
            });

            // Sort the coaches by last name
            state.coaches = Array.from(coachesMap.values()).sort((a, b) =>
                a.last_name.localeCompare(b.last_name)
            );
        },
    },
});

export const usersActions = usersSlice.actions;

export default usersSlice;
