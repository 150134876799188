import Legend, { LegendBlock } from "../../controls/Legend";
import PlayerInteractionsTileSection from "./sections/PlayerInteractionsTileSection";

const TeamPlayerInteractionsLegend = ({ zIndex = 1000 }) => {
    return (
        <Legend zIndex={zIndex}>
            <LegendBlock>
                <PlayerInteractionsTileSection />
            </LegendBlock>
        </Legend>
    );
};

export default TeamPlayerInteractionsLegend;
