import React, { useState, useEffect } from "react";
import NavButtons from "../controls/buttons/NavButtons";
import classes from "./MainHeader.module.css";

function MainHeader({
    bgColor,
    onOpen,
    backLink,
    parentWidth,
    parentHeight,
    children,
}) {
    const [widthLayout, setWidthLayout] = useState("");

    useEffect(() => {
        if (parentWidth < 568 || parentHeight < 835) {
            setWidthLayout(classes.narrow);
        } else if (parentWidth >= 992 && parentHeight >= 835) {
            setWidthLayout(classes.wide);
        } else {
            setWidthLayout("");
        }
    }, [parentWidth, parentHeight]);

    const openMenu = () => {
        onOpen && onOpen();
    };

    return (
        <div
            className={`${classes.layout_header} ${widthLayout}`}
            style={{ backgroundColor: bgColor || "inherit" }}
        >
            {onOpen && <NavButtons openMenu={openMenu} backLink={backLink} />}
            {!onOpen && <div className={classes.spacer} />}
            <header className={classes.main_header}>{children}</header>
        </div>
    );
}

export default MainHeader;
