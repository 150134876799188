import { useEffect, useState } from "react";
import SearchInput from "./SearchInput";

const UserSearchInput = ({
    users,
    selectedUserId,
    onResultSelected,
    maxListLength = 5,
    placeHolderText = "Search user...",
}) => {
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        setSelectedUser(users.find((user) => user.id === selectedUserId));
    }, [selectedUserId, users]);

    const handleSearchChange = (lcQuery) => {
        const results = Object.values(users)
            .filter((user) => {
                return (
                    user.last_name.toLowerCase().includes(lcQuery) ||
                    user.first_name.toLowerCase().includes(lcQuery) ||
                    `${user.first_name} ${user.last_name}`
                        .toLowerCase()
                        .includes(lcQuery)
                );
            })
            .map((user) => {
                return {
                    id: user.id,
                    label: `${user.first_name} ${user.last_name}`,
                };
            });
        return results;
    };

    return (
        <SearchInput
            placeHolderText={placeHolderText}
            searchHandler={handleSearchChange}
            currentSelection={{
                id: selectedUser?.id,
                label: selectedUser
                    ? `${selectedUser.first_name} ${selectedUser.last_name}`
                    : "",
            }}
            onResultSelected={onResultSelected}
            maxListLength={maxListLength}
        />
    );
};

export default UserSearchInput;
