import { useSelector } from "react-redux";
import classes from "./Playertile.module.css";
import {
    NumericIndicator,
    PercentileClassIndicator,
    IndicatorGroup,
} from "../indicators";
import CapHitIndicator from "../CapHitIndicator";
import { TrendIndicator } from "../../controls/DirectionalMarkerIndicators";
import { useEffect, useState } from "react";
import { getOrdinal } from "../../utils";
import { PLAYER_PROJECTION_BG_COLORS } from "../../constants";
import { PlayerTileModes } from "../../store/ui-slice";

export const PlayerTileWidthLayouts = {
    ULTRA_COMPACT: "ultra_compact",
    EXTRA_COMPACT: "extra_compact",
    COMPACT: "compact",
    ULTRA_NARROW: "ultra_narrow",
    EXTRA_NARROW: "extra_narrow",
    NARROW: "narrow",
    STANDARD: "standard",
    WIDE: "wide",
    // Ultra wide strong overlay means this is an ultra wide tile that is
    // possibly overlayed with a strong team logo.
    ULTRA_WIDE_STRONG_OVERLAY: "ultra_wide_strong_overlay",
    ULTRA_WIDE: "ultra_wide",
};

const widthOrder = [
    PlayerTileWidthLayouts.ULTRA_COMPACT,
    PlayerTileWidthLayouts.EXTRA_COMPACT,
    PlayerTileWidthLayouts.COMPACT,
    PlayerTileWidthLayouts.ULTRA_NARROW,
    PlayerTileWidthLayouts.EXTRA_NARROW,
    PlayerTileWidthLayouts.NARROW,
    PlayerTileWidthLayouts.STANDARD,
    PlayerTileWidthLayouts.WIDE,
    PlayerTileWidthLayouts.ULTRA_WIDE_STRONG_OVERLAY,
    PlayerTileWidthLayouts.ULTRA_WIDE,
];

export function fits_width(current_tile_width, target_tile_width) {
    const currentIndex = widthOrder.indexOf(current_tile_width);
    const targetIndex = widthOrder.indexOf(target_tile_width);

    if (currentIndex === -1 || targetIndex === -1) {
        throw new Error("Invalid tile width value.");
    }

    return currentIndex >= targetIndex;
}

export const PlayerTileBox = ({
    mainClass,
    inverseColors,
    isSelected,
    onClick,
    children,
}) => {
    return (
        <div
            className={
                classes.playertile +
                " " +
                mainClass +
                (inverseColors ? " " + classes.inverse : "") +
                (isSelected ? " " + classes.selected : "") +
                (onClick ? " " + classes.clickable : "")
            }
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export const TeamTileOverlay = ({ teamOverlay, forceSmall = false }) => {
    return (
        <div
            className={
                classes.owningrights + (teamOverlay ? " " + classes.active : "")
            }
        >
            {teamOverlay && (
                <>
                    <div
                        className={classes.owningrights_bg}
                        style={{
                            ...(teamOverlay.primary_colour && {
                                backgroundColor: teamOverlay.primary_colour,
                            }),
                        }}
                    />
                    <img
                        className={
                            classes.owningrights +
                            (forceSmall ? " " + classes.force_small : "") +
                            (!teamOverlay?.primary_colour
                                ? " " + classes.faded
                                : "")
                        }
                        src={
                            teamOverlay.primary_colour
                                ? teamOverlay.alt_logo_url
                                : teamOverlay.logo_url
                        }
                        alt="owning rights icon"
                        style={
                            teamOverlay.depth_chart_config
                                ? {
                                      objectPosition:
                                          teamOverlay.depth_chart_config
                                              .header_object_position,
                                  }
                                : {}
                        }
                    />
                </>
            )}
        </div>
    );
};

export const TileNameLine = ({ children }) => {
    return <div className={classes.tilenameline}>{children}</div>;
};

export const PlayerStatsLine = ({ children }) => {
    return <div className={classes.playerstatsline}>{children}</div>;
};

export const TileLineMainSection = ({ hideSectionInTransition, children }) => {
    return (
        <div
            className={
                classes.tileline_main_section +
                (hideSectionInTransition ? " " + classes.transition_hide : "")
            }
        >
            {children}
        </div>
    );
};

export const TileLineDecoratorsSection = ({
    hideSectionInTransition,
    children,
}) => {
    return (
        <div
            className={
                classes.tileline_decorators_section +
                (hideSectionInTransition ? " " + classes.transition_hide : "")
            }
        >
            {children}
        </div>
    );
};

export const PlayerPerformanceDecorators = ({
    player,
    widthLayout = PlayerTileWidthLayouts.STANDARD,
    inverseColors,
}) => (
    <div className={classes.performance_decorators}>
        {player.current_trend_class === -1 &&
            fits_width(widthLayout, PlayerTileWidthLayouts.COMPACT) && (
                <div className={classes.trend_indicator}>
                    <TrendIndicator
                        size={10}
                        isTrendingUp={false}
                        inverseColors={inverseColors}
                    />
                </div>
            )}
        {player.current_trend_class === 1 &&
            fits_width(widthLayout, PlayerTileWidthLayouts.COMPACT) && (
                <div className={classes.trend_indicator}>
                    <TrendIndicator
                        size={10}
                        isTrendingUp={true}
                        inverseColors={inverseColors}
                    />
                </div>
            )}
        {player.pillars_count === 4 && (
            <div className={classes.playerpillars + " " + classes.fourpillar}>
                <img
                    src={"/static/static/dashboards/4pillar.png"}
                    alt="4 pillar player icon"
                />
            </div>
        )}
        {player.pillars_count === 5 && (
            <div className={classes.playerpillars + " " + classes.fivepillar}>
                <img
                    src={"/static/static/dashboards/5pillar.png"}
                    alt="5 pillar player icon"
                />
            </div>
        )}
    </div>
);

export const PlayerContractDecorators = ({
    player,
    widthLayout = PlayerTileWidthLayouts.STANDARD,
    inverseColors,
}) => (
    <>
        {(player.contract_years > 0 || player.contract_extension > 0) && (
            <span className={classes.contract_length}>
                {player.contract_years > 0 ? player.contract_years + "y" : ""}
                {player.contract_extension > 0 &&
                    (fits_width(widthLayout, PlayerTileWidthLayouts.COMPACT)
                        ? " +" + player.contract_extension
                        : "+")}
            </span>
        )}
    </>
);

export const GoalieSavePercentageIndicator = ({
    player,
    metrics,
    fullLabels,
    inverseColors,
    fixedWidth,
}) => {
    return (
        <NumericIndicator
            value={metrics && metrics.SP}
            percentile={metrics && metrics.SPperc}
            label={fullLabels ? "es sv%" : null}
            precision="3"
            fixedWidth={fixedWidth}
            inverseColors={inverseColors}
        />
    );
};

export const GoaliePerformanceIndicator = ({
    player,
    metrics,
    widthLayout = PlayerTileWidthLayouts.STANDARD,
    inverseColors,
}) => {
    const es_label = fits_width(widthLayout, PlayerTileWidthLayouts.NARROW)
        ? "es"
        : "";
    const pk_label = fits_width(widthLayout, PlayerTileWidthLayouts.NARROW)
        ? "pk"
        : "";

    return (
        <>
            {fits_width(widthLayout, PlayerTileWidthLayouts.ULTRA_NARROW) && (
                <IndicatorGroup
                    consistency_percentile={metrics && metrics.QS}
                    indicators={[
                        {
                            value: metrics && metrics.ES,
                            label: es_label,
                            key: "es",
                        },
                        {
                            value: metrics && metrics.PK,
                            label: pk_label,
                            key: "pk",
                        },
                    ]}
                    inverseColors={inverseColors}
                />
            )}
            {fits_width(widthLayout, PlayerTileWidthLayouts.STANDARD) && (
                <PercentileClassIndicator
                    value={metrics && metrics.SQ}
                    label="sq"
                    inverseColors={inverseColors}
                />
            )}
        </>
    );
};

export const SkaterPointsIndicator = ({
    player,
    metrics,
    fullLabels,
    inverseColors,
    fixedWidth,
}) => {
    return (
        <NumericIndicator
            value={metrics && metrics.P}
            percentile={metrics && metrics.Pperc}
            label={fullLabels ? "pp60" : null}
            precision={"2"}
            fixedWidth={fixedWidth}
            inverseColors={inverseColors}
        />
    );
};

export const SkaterPerformanceIndicator = ({
    player,
    metrics,
    inverseColors,
}) => {
    return (
        <div className={classes.skater_performance_group}>
            <PercentileClassIndicator
                value={metrics && metrics.O}
                label="o"
                decorator={{
                    value: metrics && metrics.Ouc,
                    label: "*",
                }}
                diff_value={metrics && metrics.Oq}
                inverseColors={inverseColors}
            />
            <PercentileClassIndicator
                value={metrics && metrics.D}
                label="d"
                decorator={{
                    value: metrics && metrics.Duc,
                    label: "*",
                }}
                diff_value={metrics && metrics.Dq}
                inverseColors={inverseColors}
            />
        </div>
    );
};

export const SkaterUsageIndicator = ({ player, metrics, inverseColors }) => {
    return (
        <>
            <PercentileClassIndicator
                value={metrics && metrics.m}
                label="m"
                inverseColors={inverseColors}
            />
            <PercentileClassIndicator
                value={metrics && metrics.z}
                label="z"
                inverseColors={inverseColors}
            />
        </>
    );
};

export const PlayerPerformanceStatsTileSection = ({
    player,
    metrics,
    fullLabels,
    widthLayout = PlayerTileWidthLayouts.STANDARD,
    inverseColors,
}) => {
    if (player.position === "G") {
        return (
            <div
                className={
                    classes.goalie_performance_section +
                    " " +
                    classes.indicator_group
                }
            >
                <GoalieSavePercentageIndicator
                    player={player}
                    metrics={metrics}
                    fullLabels={fullLabels}
                    fixedWidth={true}
                    inverseColors={inverseColors}
                />
                <GoaliePerformanceIndicator
                    player={player}
                    metrics={metrics}
                    widthLayout={widthLayout}
                    inverseColors={inverseColors}
                />
            </div>
        );
    } else {
        return (
            <div className={classes.indicator_group}>
                <SkaterPointsIndicator
                    player={player}
                    metrics={metrics}
                    fullLabels={fullLabels}
                    fixedWidth={true}
                    inverseColors={inverseColors}
                />
                {fits_width(
                    widthLayout,
                    PlayerTileWidthLayouts.ULTRA_NARROW
                ) && (
                    <SkaterPerformanceIndicator
                        player={player}
                        metrics={metrics}
                        inverseColors={inverseColors}
                    />
                )}
                {fits_width(widthLayout, PlayerTileWidthLayouts.STANDARD) && (
                    <SkaterUsageIndicator
                        player={player}
                        metrics={metrics}
                        inverseColors={inverseColors}
                    />
                )}
            </div>
        );
    }
};

export const GamesPlayedStat = ({ ep_stats, metrics, inverseColors }) => {
    return (
        <NumericIndicator
            value={
                (!ep_stats || (ep_stats && ep_stats.GP === 0)) && !metrics
                    ? undefined
                    : !metrics?.games_played
                    ? ep_stats?.GP
                    : !metrics.games_played_is_sample ||
                      !ep_stats ||
                      metrics.games_played >= ep_stats?.GP
                    ? metrics.games_played
                    : `${metrics.games_played}/${ep_stats?.GP}`
            }
            label={
                metrics &&
                metrics.games_played &&
                metrics.games_played_is_sample &&
                metrics.games_played !== ep_stats?.GP
                    ? "gwd"
                    : "gp"
            }
            isWarning={
                metrics &&
                metrics.games_played &&
                metrics.games_played_is_sample &&
                metrics.games_played !== ep_stats?.GP
            }
            inverseColors={inverseColors}
        />
    );
};

export const GoalieSavePercentageStat = ({
    metrics,
    ep_stats,
    inverseColors,
    fixedWidth = true,
}) => {
    return (
        <NumericIndicator
            value={
                !ep_stats || (ep_stats && ep_stats.GP === 0)
                    ? undefined
                    : metrics?.SP || ep_stats?.SVP / 100.0
            }
            label={"sv"}
            fixedWidth={fixedWidth}
            precision={"3"}
            inverseColors={inverseColors}
        />
    );
};

export const GoalieGoalsAgainstAvgStat = ({ ep_stats, inverseColors }) => {
    return (
        <NumericIndicator
            value={ep_stats?.GAA}
            label={"gaa"}
            fixedWidth={true}
            precision={"2"}
            inverseColors={inverseColors}
        />
    );
};

export const GoalieShutoutsStat = ({
    ep_stats,
    inverseColors,
    fixedWidth = true,
}) => {
    return (
        <NumericIndicator
            value={ep_stats?.SO}
            label={"so"}
            fixedWidth={fixedWidth}
            precision={"0"}
            inverseColors={inverseColors}
        />
    );
};

export const SkaterPointsStats = ({ ep_stats, inverseColors }) => {
    return (
        <div className={classes.indicator_group + " " + classes.nottight}>
            <NumericIndicator
                value={
                    !ep_stats || (ep_stats && ep_stats.GP === 0)
                        ? undefined
                        : ep_stats.G
                }
                label={"g"}
                inverseColors={inverseColors}
            />
            <NumericIndicator
                value={
                    !ep_stats || (ep_stats && ep_stats.GP === 0)
                        ? undefined
                        : ep_stats.A
                }
                label={"a"}
                inverseColors={inverseColors}
            />
            <NumericIndicator
                value={
                    !ep_stats || (ep_stats && ep_stats.GP === 0)
                        ? undefined
                        : ep_stats.PTS
                }
                label={"p"}
                inverseColors={inverseColors}
            />
        </div>
    );
};

export const SkaterPlusMinusStat = ({ ep_stats, inverseColors }) => {
    return (
        <NumericIndicator
            value={
                !ep_stats || (ep_stats && ep_stats.GP === 0)
                    ? undefined
                    : (ep_stats.PM > 0 ? "+" : "") + ep_stats.PM
            }
            label={"±"}
            inverseColors={inverseColors}
        />
    );
};

export const SkaterPenaltyMinutesStat = ({ ep_stats, inverseColors }) => {
    return (
        <NumericIndicator
            value={
                !ep_stats || (ep_stats && ep_stats.GP === 0)
                    ? undefined
                    : ep_stats.PIM
            }
            label={"pim"}
            inverseColors={inverseColors}
        />
    );
};

export const PlayerTradStatsTileSection = ({
    player,
    metrics,
    ep_stats,
    widthLayout = PlayerTileWidthLayouts.STANDARD,
    hasOverlay,
    inverseColors,
}) => {
    if (player.position === "G") {
        return (
            <div className={classes.indicator_group}>
                {(fits_width(
                    widthLayout,
                    PlayerTileWidthLayouts.EXTRA_NARROW
                ) ||
                    !fits_width(
                        widthLayout,
                        PlayerTileWidthLayouts.COMPACT
                    )) && (
                    <div className={classes.gp_indicator}>
                        <GamesPlayedStat
                            ep_stats={ep_stats}
                            inverseColors={inverseColors}
                        />
                    </div>
                )}
                {fits_width(widthLayout, PlayerTileWidthLayouts.COMPACT) && (
                    <GoalieSavePercentageStat
                        metrics={metrics}
                        ep_stats={ep_stats}
                        inverseColors={inverseColors}
                    />
                )}
                {(fits_width(widthLayout, PlayerTileWidthLayouts.WIDE) ||
                    (fits_width(
                        widthLayout,
                        PlayerTileWidthLayouts.ULTRA_WIDE_STRONG_OVERLAY
                    ) &&
                        !hasOverlay)) && (
                    <GoalieGoalsAgainstAvgStat
                        ep_stats={ep_stats}
                        inverseColors={inverseColors}
                    />
                )}
                {fits_width(widthLayout, PlayerTileWidthLayouts.ULTRA_WIDE) &&
                    !hasOverlay && (
                        <GoalieShutoutsStat
                            ep_stats={ep_stats}
                            inverseColors={inverseColors}
                        />
                    )}
            </div>
        );
    } else {
        return (
            <div className={classes.indicator_group}>
                {(fits_width(
                    widthLayout,
                    PlayerTileWidthLayouts.EXTRA_NARROW
                ) ||
                    !fits_width(
                        widthLayout,
                        PlayerTileWidthLayouts.COMPACT
                    )) && (
                    <div className={classes.gp_indicator}>
                        <GamesPlayedStat
                            ep_stats={ep_stats}
                            inverseColors={inverseColors}
                        />
                    </div>
                )}
                {fits_width(widthLayout, PlayerTileWidthLayouts.COMPACT) && (
                    <div
                        className={
                            classes.skaters_points_indicators +
                            (fits_width(
                                widthLayout,
                                PlayerTileWidthLayouts.WIDE
                            )
                                ? " " + classes.spaced
                                : "")
                        }
                    >
                        <SkaterPointsStats
                            ep_stats={ep_stats}
                            inverseColors={inverseColors}
                        />
                    </div>
                )}
                {(fits_width(widthLayout, PlayerTileWidthLayouts.WIDE) ||
                    (fits_width(
                        widthLayout,
                        PlayerTileWidthLayouts.ULTRA_WIDE_STRONG_OVERLAY
                    ) &&
                        !hasOverlay)) && (
                    <div className={classes.plusminus_indicator}>
                        <SkaterPlusMinusStat ep_stats={ep_stats} />
                    </div>
                )}
                {fits_width(widthLayout, PlayerTileWidthLayouts.ULTRA_WIDE) &&
                    !hasOverlay && (
                        <div className={classes.pim_indicator}>
                            <SkaterPenaltyMinutesStat ep_stats={ep_stats} />
                        </div>
                    )}
            </div>
        );
    }
};

export const PlayerCapStatsTileSection = ({
    player,
    capHit,
    inverseColors,
    widthLayout = PlayerTileWidthLayouts.STANDARD,
}) => {
    return (
        <>
            {player.projected_free_agency_class === "" &&
                !player.current_ahl_salary && (
                    <span className={classes.no_cap_hit}>-</span>
                )}
            {(player.projected_free_agency_class !== "" ||
                player.current_ahl_salary > 0) && (
                <CapHitIndicator
                    player={player}
                    capHit={capHit}
                    inverseColors={inverseColors}
                    showBar={fits_width(
                        widthLayout,
                        PlayerTileWidthLayouts.NARROW
                    )}
                    extrasDisplayMode={
                        fits_width(widthLayout, PlayerTileWidthLayouts.WIDE)
                            ? "number"
                            : fits_width(
                                  widthLayout,
                                  PlayerTileWidthLayouts.EXTRA_COMPACT
                              )
                            ? "dot"
                            : "none"
                    }
                />
            )}
        </>
    );
};

export const DecoratorPill = ({ label, flat = false, additionalClasses }) => {
    return (
        <div
            className={
                classes.decorator_pill +
                " " +
                (flat ? classes.flat + " " : "") +
                additionalClasses
            }
        >
            {label}
        </div>
    );
};

export const PlayerFreeAgencyPill = ({
    freeAgencyClass,
    isArbitrationEligible,
    flat = false,
}) => {
    return (
        <DecoratorPill
            label={
                freeAgencyClass === classes.projected_ufa
                    ? "UFA"
                    : freeAgencyClass === classes.projected_rfa
                    ? "RFA" + (isArbitrationEligible ? "+" : "")
                    : freeAgencyClass === classes.pto
                    ? "PTO"
                    : freeAgencyClass === classes.ato
                    ? "ATO"
                    : "10.2c"
            }
            flat={flat}
            additionalClasses={
                classes.projected_free_agency_pill +
                " " +
                (freeAgencyClass ? freeAgencyClass : classes.light_label)
            }
        />
    );
};

export const PlayerScoutingProjectionPill = ({
    scoutingProjection,
    inverseColors,
}) => {
    return (
        <div
            className={
                classes.scouting_projection +
                (inverseColors ? " " + classes.inverse : "")
            }
            style={{
                backgroundColor:
                    PLAYER_PROJECTION_BG_COLORS[scoutingProjection],
            }}
        >
            {scoutingProjection}
        </div>
    );
};

export const PlayerContractClausePill = ({
    clause,
    flat = false,
    inverseColors = false,
}) => {
    return (
        <DecoratorPill
            label={
                clause === "ntc"
                    ? "NTC"
                    : clause === "nmc"
                    ? "NMC"
                    : clause === "m_ntc"
                    ? "mNTC"
                    : clause === "elc"
                    ? "ELC"
                    : clause === "perf_bonus"
                    ? "$"
                    : ""
            }
            flat={flat}
            additionalClasses={
                classes[clause + "_clause"] +
                " " +
                classes.contract_clause_pill +
                (inverseColors ? " " + classes.inverse : "")
            }
        />
    );
};

export const RankingIndicator = ({ rank, inverseColors, isExample }) => {
    return (
        <div
            className={
                classes.ranking_indicator +
                (inverseColors ? " " + classes.inverse : "") +
                (isExample ? " " + classes.example : "")
            }
        >
            {getOrdinal(rank)}
        </div>
    );
};

export const PlayerWaiversExemptionPill = ({ flat = false }) => {
    return (
        <div
            className={
                classes.waivers_exemption + (flat ? " " + classes.flat : "")
            }
        >
            &#8681;
        </div>
    );
};

export const PlayerWaiversAndFreeAgencyDecorators = ({
    player,
    projectedFreeAgencyClass,
    inverseColors,
}) => {
    return (
        <>
            {player.is_signed && !player.requires_waivers ? (
                <PlayerWaiversExemptionPill />
            ) : (
                ""
            )}
            {(player.projected_free_agency_class !== "" ||
                player.is_pto ||
                player.is_ato) && (
                <PlayerFreeAgencyPill
                    freeAgencyClass={projectedFreeAgencyClass}
                    isArbitrationEligible={
                        player.is_projected_arbitration_eligible
                    }
                />
            )}
        </>
    );
};

export const PlayerScoutingTileSection = ({
    player,
    metrics,
    skill_ratings,
    widthLayout = PlayerTileWidthLayouts.STANDARD,
    hasOverlay,
    inverseColors,
}) => {
    return (
        <>
            {(player.scouting_revised_projection !== "" ||
                player.scouting_consensus_projection !== "") && (
                <PlayerScoutingProjectionPill
                    scoutingProjection={
                        player.scouting_revised_projection !== ""
                            ? player.scouting_revised_projection
                            : player.scouting_consensus_projection
                    }
                    inverseColors={inverseColors}
                />
            )}
            <div className={classes.indicator_group}>
                <>
                    {skill_ratings && Object.keys(skill_ratings).length > 0 && (
                        <>
                            {player.position === "G" &&
                                fits_width(
                                    widthLayout,
                                    PlayerTileWidthLayouts.ULTRA_NARROW
                                ) && (
                                    <NumericIndicator
                                        value={skill_ratings.athleticism}
                                        precision="1"
                                        label={"a"}
                                        fixedWidth={true}
                                        inverseColors={inverseColors}
                                    />
                                )}
                            {player.position !== "G" &&
                                fits_width(
                                    widthLayout,
                                    PlayerTileWidthLayouts.ULTRA_NARROW
                                ) && (
                                    <NumericIndicator
                                        value={
                                            skill_ratings.offensive_hockey_sense
                                        }
                                        precision="1"
                                        label={"ohs"}
                                        fixedWidth={true}
                                        inverseColors={inverseColors}
                                    />
                                )}
                            {fits_width(
                                widthLayout,
                                PlayerTileWidthLayouts.NARROW
                            ) && (
                                <NumericIndicator
                                    value={skill_ratings.skating}
                                    precision="1"
                                    label={"sk"}
                                    fixedWidth={true}
                                    inverseColors={inverseColors}
                                />
                            )}
                        </>
                    )}
                    {metrics &&
                        fits_width(
                            widthLayout,
                            PlayerTileWidthLayouts.ULTRA_WIDE_STRONG_OVERLAY
                        ) &&
                        !hasOverlay &&
                        metrics.TAS !== 0 &&
                        metrics.TAS && (
                            <>
                                <NumericIndicator
                                    value={metrics.TAS}
                                    percentile={metrics.TASperc}
                                    precision="2"
                                    canBeNegative={true}
                                    label={"tas"}
                                    fixedWidth={true}
                                    inverseColors={inverseColors}
                                />
                                {metrics.TASrank < 11 &&
                                    widthLayout ===
                                        PlayerTileWidthLayouts.ULTRA_WIDE && (
                                        <RankingIndicator
                                            rank={metrics.TASrank}
                                            inverseColors={inverseColors}
                                        />
                                    )}
                            </>
                        )}
                </>
            </div>
        </>
    );
};

const Playertile = ({
    player,
    namedecoration,
    namedecorationclass,
    altName,
    tileView = PlayerTileModes.PERFORMANCE,
    showLaterality = true,
    onClick,
    overlayDraftedTeam = false,
    overlayOwningTeam = false,
    showProjectedFreeAgencyStatus = false,
    isSelected = false,
    widthLayout = PlayerTileWidthLayouts.STANDARD,
}) => {
    const teams = useSelector((state) => state.teams.teams);
    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const store_player = useSelector(
        (state) => state.players.players[player.id]
    );

    const showFreeAgencyOnExpiredOnly = useSelector(
        (state) => state.ui.systemConfiguration?.showFreeAgencyOnExpiredOnly
    );

    const [tileViewMode, setTileViewMode] = useState(tileView);
    const [tileInTransition, setTileInTransition] = useState(false);

    useEffect(() => {
        if (tileViewMode !== tileView) {
            setTileInTransition(true);
            setTimeout(() => {
                setTileViewMode(tileView);
                setTileInTransition(false);
            }, 500);
        }
    }, [tileView, tileViewMode]);

    const finalNamedecoration =
        namedecoration ||
        (store_player && store_player.injured_reserve_class
            ? store_player.injured_reserve_class
            : store_player.is_other_offroster
            ? "OTH"
            : store_player.is_on_loan
            ? "LOAN"
            : null);

    const overlayTeamId =
        (overlayDraftedTeam && store_player.nhl_drafted_team_id) ||
        (overlayOwningTeam &&
            (store_player.nhl_rights_owner_team_id ||
                store_player.ahl_contract_team_id));

    const teamOverlay = overlayTeamId &&
        teams &&
        teams[overlayTeamId] && {
            ...teams[overlayTeamId],
            depth_chart_config:
                teams_datastashes &&
                teams_datastashes[overlayTeamId] &&
                teams_datastashes[overlayTeamId].depth_chart_config,
        };

    if (overlayOwningTeam && teamOverlay) {
        // Remove the primary colour to only show logo
        teamOverlay.primary_colour = null;
    }

    const projectedFreeAgencyClass =
        store_player &&
        (store_player.is_projected_ufa
            ? classes.projected_ufa
            : store_player.is_projected_rfa
            ? classes.projected_rfa
            : !store_player.is_unsigned_draftee &&
              store_player.projected_free_agency_class !== ""
            ? classes.ten_2c
            : store_player.is_pto
            ? classes.pto
            : store_player.is_ato
            ? classes.ato
            : null);
    // Years on contract is the sum of the current contract years and the
    // contract extension.
    // In the case where the current contract years is 0, but the player has a
    // contract extension, yearsOnContract will be the contract extension + 1.
    const yearsOnContract =
        store_player &&
        (store_player.contract_years
            ? store_player.contract_years +
              (store_player.contract_extension || 0)
            : store_player.contract_extension
            ? store_player.contract_extension + 1
            : 0);

    const mainTileClass =
        store_player &&
        (showProjectedFreeAgencyStatus
            ? store_player.nhl_rights_owner_team_id && !store_player.is_tryout
                ? // RFAs with qualifying offers will have 0 years on their contract,
                  // but we want to show them as RFAs.
                  projectedFreeAgencyClass &&
                  ((!showFreeAgencyOnExpiredOnly && yearsOnContract <= 1) ||
                      (showFreeAgencyOnExpiredOnly && yearsOnContract === 0))
                    ? projectedFreeAgencyClass
                    : !store_player.is_signed
                    ? classes.unsigned +
                      (store_player.ahl_contract_team_id
                          ? " " + classes.ahl_contract
                          : "")
                    : classes.basetile
                : store_player.ahl_contract_team_id
                ? classes.ahl_contract
                : store_player.is_ufa
                ? classes.unsigned
                : classes.basetile
            : store_player.is_ufa
            ? classes.unsigned
            : classes.basetile) +
            (showLaterality && store_player.laterality
                ? " " +
                  (store_player.laterality === "L"
                      ? classes.lefty
                      : classes.righty)
                : "");
    const inverseColors = teamOverlay && teamOverlay.primary_colour;

    const handleClick = () => {
        if (onClick) {
            onClick(player.id);
        }
    };

    return (
        <PlayerTileBox
            mainClass={mainTileClass}
            inverseColors={inverseColors}
            isSelected={isSelected}
            onClick={handleClick}
        >
            <TeamTileOverlay teamOverlay={teamOverlay} />
            <TileNameLine>
                <TileLineMainSection>
                    <span className={classes.tile_main_name}>
                        {altName
                            ? altName
                            : fits_width(
                                  widthLayout,
                                  PlayerTileWidthLayouts.STANDARD
                              )
                            ? store_player.known_name
                            : fits_width(
                                  widthLayout,
                                  PlayerTileWidthLayouts.EXTRA_NARROW
                              )
                            ? store_player.last_name
                            : `${store_player.first_name[0]}${store_player.last_name[0]}`}
                    </span>
                    {!altName &&
                        fits_width(
                            widthLayout,
                            PlayerTileWidthLayouts.NARROW
                        ) && (
                            <span className={classes.playerage}>
                                {" "}
                                {store_player.age}
                            </span>
                        )}
                    {finalNamedecoration &&
                        fits_width(
                            widthLayout,
                            PlayerTileWidthLayouts.STANDARD
                        ) && (
                            <span
                                className={
                                    classes.namedecoration +
                                    (namedecorationclass
                                        ? " " + namedecorationclass
                                        : "")
                                }
                            >
                                {" "}
                                {finalNamedecoration}
                            </span>
                        )}
                </TileLineMainSection>
                <TileLineDecoratorsSection
                    hideSectionInTransition={tileInTransition}
                >
                    {[
                        PlayerTileModes.PERFORMANCE,
                        PlayerTileModes.TRAD_STATS,
                        PlayerTileModes.SCOUTING,
                    ].includes(tileViewMode) && (
                        <PlayerPerformanceDecorators
                            player={player}
                            widthLayout={widthLayout}
                            inverseColors={inverseColors}
                        />
                    )}
                    {tileViewMode === PlayerTileModes.CAP && (
                        <PlayerContractDecorators
                            player={store_player}
                            widthLayout={widthLayout}
                            inverseColors={inverseColors}
                        />
                    )}
                </TileLineDecoratorsSection>
            </TileNameLine>
            <PlayerStatsLine>
                <TileLineMainSection hideSectionInTransition={tileInTransition}>
                    {tileViewMode === PlayerTileModes.PERFORMANCE && (
                        <PlayerPerformanceStatsTileSection
                            player={player}
                            metrics={player.metrics}
                            widthLayout={widthLayout}
                            hasOverlay={!!teamOverlay}
                            inverseColors={inverseColors}
                        />
                    )}
                    {tileViewMode === PlayerTileModes.TRAD_STATS && (
                        <PlayerTradStatsTileSection
                            player={store_player}
                            metrics={player.metrics}
                            ep_stats={player.eliteprospects_stats?.regular}
                            widthLayout={widthLayout}
                            hasOverlay={!!teamOverlay}
                            inverseColors={inverseColors}
                        />
                    )}
                    {tileViewMode === PlayerTileModes.CAP && (
                        <PlayerCapStatsTileSection
                            player={store_player}
                            capHit={
                                store_player.current_base_annual_average_value
                            }
                            widthLayout={widthLayout}
                            hasOverlay={!!teamOverlay}
                            inverseColors={inverseColors}
                        />
                    )}
                    {tileViewMode === PlayerTileModes.SCOUTING && (
                        <PlayerScoutingTileSection
                            player={store_player}
                            metrics={player.metrics}
                            skill_ratings={player.skill_ratings}
                            widthLayout={widthLayout}
                            hasOverlay={!!teamOverlay}
                            inverseColors={inverseColors}
                        />
                    )}
                </TileLineMainSection>
                <TileLineDecoratorsSection
                    hideSectionInTransition={tileInTransition}
                >
                    {tileViewMode === PlayerTileModes.CAP &&
                        fits_width(
                            widthLayout,
                            PlayerTileWidthLayouts.EXTRA_NARROW
                        ) && (
                            <PlayerWaiversAndFreeAgencyDecorators
                                player={store_player}
                                projectedFreeAgencyClass={
                                    projectedFreeAgencyClass
                                }
                                inverseColors={inverseColors}
                            />
                        )}
                </TileLineDecoratorsSection>
            </PlayerStatsLine>
        </PlayerTileBox>
    );
};

export default Playertile;
