import classes from "./CapHitIndicator.module.css";
import { useSalaryCap } from "../hooks/use-salary-cap";
import { formatSalaryString } from "../utils";
import { SalaryExtraDotIndicator } from "./indicators";

export const TOP_CAP_HIT = 14000000.0;

export const BaseSalaryNumber = ({ salary, isNonRoster, isExample }) => {
    return (
        <span
            className={
                classes.cap_hit_base_salary +
                (isNonRoster ? " " + classes.off_cap : "")
            }
        >
            {isExample && salary}
            {!isExample && formatSalaryString(salary)}
        </span>
    );
};

export const BonusNumber = ({ bonus, isNonRoster, isExample }) => {
    return (
        <span
            className={
                classes.cap_hit_bonus +
                (isNonRoster ? " " + classes.off_cap : "")
            }
        >
            {" +"}
            {isExample && bonus}
            {!isExample && formatSalaryString(bonus)}
        </span>
    );
};

export const AhlSalaryNumber = ({ salary, isExample }) => {
    return (
        <span className={classes.ahl_salary}>
            {" "}
            {isExample && salary}
            {!isExample && formatSalaryString(salary)}
        </span>
    );
};

export const CapHitLabel = ({
    annualAverageValue,
    bonus,
    ahlSalary,
    isNonRoster,
    extrasDisplayMode = "number",
}) => {
    return (
        <div className={classes.cap_hit_label}>
            {annualAverageValue && (
                <BaseSalaryNumber
                    salary={annualAverageValue}
                    isNonRoster={isNonRoster}
                />
            )}
            {extrasDisplayMode === "number" && bonus && bonus > 0 && (
                <BonusNumber bonus={bonus} isNonRoster={isNonRoster} />
            )}
            {extrasDisplayMode === "dot" && bonus && bonus > 0 && (
                <div className={classes.dot_indicator}>
                    <SalaryExtraDotIndicator
                        extra_type={isNonRoster ? "bonus_non_roster" : "bonus"}
                    />
                </div>
            )}
            {extrasDisplayMode === "number" && ahlSalary && (
                <AhlSalaryNumber salary={ahlSalary} />
            )}
            {extrasDisplayMode === "dot" && ahlSalary && (
                <div className={classes.dot_indicator}>
                    <SalaryExtraDotIndicator extra_type={"ahl"} />
                </div>
            )}
        </div>
    );
};

export const CapHitBar = ({
    annualAverageValue,
    capBase,
    buriedCap,
    bonus,
    ahlSalary,
    isNonRoster,
    includeLabel,
    showBar = true,
    extrasDisplayMode = "number",
}) => {
    const { CURRENT_BURIED_CAP_THRESHOLD } = useSalaryCap();

    return (
        <div className={classes.cap_hit_bar_container}>
            {showBar && (
                <>
                    {capBase > 0 && (
                        <div
                            className={
                                classes.cap_hit_bar +
                                " " +
                                classes.base_salary +
                                (isNonRoster &&
                                annualAverageValue >
                                    CURRENT_BURIED_CAP_THRESHOLD
                                    ? " " + classes.buried_cap_penalty
                                    : "") +
                                (bonus && bonus > 0
                                    ? " " + classes.with_bonus
                                    : "")
                            }
                            style={{
                                width: `calc((100%${
                                    includeLabel ? " - 32px" : ""
                                }) * ${capBase / TOP_CAP_HIT})`,
                            }}
                        ></div>
                    )}
                    {buriedCap > 0 && (
                        <div
                            className={
                                classes.cap_hit_bar +
                                " " +
                                classes.base_salary +
                                " " +
                                classes.off_cap +
                                (capBase > 0 ? " " + classes.buried_cap : "") +
                                (bonus && bonus > 0
                                    ? " " + classes.with_bonus
                                    : "")
                            }
                            style={{
                                width: `calc((100%${
                                    includeLabel ? " - 32px" : ""
                                }) * ${buriedCap / TOP_CAP_HIT})`,
                            }}
                        ></div>
                    )}
                    {bonus && bonus > 0 && (
                        <div
                            className={
                                classes.cap_hit_bar +
                                " " +
                                classes.bonus +
                                (isNonRoster ? " " + classes.off_cap : "")
                            }
                            style={{
                                width: `calc((100%${
                                    includeLabel ? " - 32px" : ""
                                }) * ${bonus / TOP_CAP_HIT})`,
                            }}
                        ></div>
                    )}
                </>
            )}

            {includeLabel && (
                <CapHitLabel
                    annualAverageValue={annualAverageValue}
                    bonus={bonus}
                    ahlSalary={ahlSalary}
                    isNonRoster={isNonRoster}
                    extrasDisplayMode={extrasDisplayMode}
                />
            )}
        </div>
    );
};

const CapHitIndicator = ({
    player,
    capHit,
    showBar = true,
    extrasDisplayMode = "number",
    inverseColors,
}) => {
    const { getPlayerCapBase, getPlayerBuriedCap } = useSalaryCap();

    const cap_base = getPlayerCapBase(player, capHit);
    const buried_cap = getPlayerBuriedCap(player, capHit);

    return (
        <div className={classes.cap_hit_indicator}>
            <CapHitBar
                annualAverageValue={capHit}
                capBase={cap_base}
                buriedCap={buried_cap}
                bonus={player.current_bonus_annual_average_value}
                ahlSalary={player.current_ahl_salary}
                isNonRoster={player.is_cleared_non_roster}
                includeLabel={true}
                showBar={showBar}
                extrasDisplayMode={extrasDisplayMode}
            />
        </div>
    );
};

export default CapHitIndicator;
