import classes from "./PlayerDevelopmentPanel.module.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SlideInPanel from "../../layout/SlideInPanel";
import PlayerHeader from "../player-header/PlayerHeader";
import OverheadTitleSection from "../../layout/OverheadTitleSection";
import {
    fetchPlayerDevelopmentPriorities,
    fetchPlayerDevelopmentSessions,
} from "../../store/players-actions";
import PlayerDevelopmentPriorityView from "../PlayerDevelopmentPriorityView";
import IconButton from "../../controls/buttons/IconButton";
import useAuth from "../../hooks/use-auth";
import { shortDateString } from "../../utils";
import {
    PlayerNoteHeader,
    PlayerNoteText,
    PlayerNoteOwner,
} from "../PlayerNote";
import { ExpandableContainer } from "../../layout/ExpandableSection";
import {
    usePlayerDevelopmentPriorityOutcomes,
    usePlayerDevelopmentSessionTypes,
} from "../../hooks/enum-helpers";

import {
    PlayerDevelopmentPriorityDialog,
    PlayerDevelopmentSessionDialog,
    player_development_mp_track,
} from "../PlayerDevelopmentDialogs";

function PlayerDevelopmentPanel({
    playerId,
    competitionId,
    isOpen,
    onCloseHandler,
    targetWidthHandler,
}) {
    const dispatch = useDispatch();
    const { user, checkPermission } = useAuth();

    const canViewPlayerDevelopment = checkPermission(
        "core.can_view_player_development_priorities"
    );
    const canAddUpdatePlayerDevelopmentPriorities = checkPermission(
        "core.can_add_update_player_development_priorities"
    );
    const canAddUpdatePlayerDevelopmentSessions = checkPermission(
        "core.can_add_update_player_development_sessions_and_notes"
    );

    const contentWidth = 480;
    const contentPaddingWidth = 20;

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );

    const { playerDevelopmentPriorityOutcomesValues } =
        usePlayerDevelopmentPriorityOutcomes();

    const { playerDevelopmentSessionTypesValues } =
        usePlayerDevelopmentSessionTypes();

    const targetWidth = contentWidth + contentPaddingWidth;

    useEffect(() => {
        if (isOpen) {
            targetWidthHandler && targetWidthHandler(targetWidth);
        } else {
            targetWidthHandler && targetWidthHandler(null);
        }
    }, [isOpen, targetWidth, targetWidthHandler]);

    const [confirmedPlayerId, setConfirmedPlayerId] = useState(null);
    const [selectedDevelopmentPriority, setSelectedDevelopmentPriority] =
        useState(null);
    const [filteredDevelopmentPriority, setFilteredDevelopmentPriority] =
        useState(null);
    const [
        isDevelopmentPriorityDialogOpen,
        setIsDevelopmentPriorityDialogOpen,
    ] = useState(false);
    const [
        isDevelopmentPriorityDialogCompleteMode,
        setIsDevelopmentPriorityDialogCompleteMode,
    ] = useState(false);

    const [selectedSession, setSelectedSession] = useState(null);
    const [isSessionDialogOpen, setIsSessionDialogOpen] = useState(false);

    const player = useSelector(
        (state) => state.players.players[confirmedPlayerId]
    );

    const [playerChangeWait, setPlayerChangeWait] = useState(false);

    useEffect(() => {
        setPlayerChangeWait(true);
        dispatch(fetchPlayerDevelopmentPriorities(playerId));
        dispatch(fetchPlayerDevelopmentSessions(playerId));
        setTimeout(() => {
            setConfirmedPlayerId(playerId);
            setPlayerChangeWait(false);
        }, 175);
    }, [playerId, dispatch]);

    const playerDevelopmentPriorities = useSelector(
        (state) => state.players.developmentpriorities[confirmedPlayerId]
    );

    const playerDevelopmentSessions = useSelector(
        (state) => state.players.developmentsessions[confirmedPlayerId]
    );

    const playerDevelopmentSessionsByPriority = playerDevelopmentSessions
        ? playerDevelopmentSessions.reduce((acc, session) => {
              session.developmentpriority_ids.forEach((priorityId) => {
                  if (!acc[priorityId]) {
                      acc[priorityId] = [];
                  }
                  acc[priorityId].push(session);
              });

              return acc;
          }, {})
        : {};

    const activeDevelopmentPriorities = playerDevelopmentPriorities
        ? playerDevelopmentPriorities.reduce((acc, developmentPriority) => {
              if (developmentPriority.end_date === null) {
                  acc.push(developmentPriority);
              }
              return acc;
          }, [])
        : [];

    const completedDevelopmentPriorities = playerDevelopmentPriorities
        ? playerDevelopmentPriorities.reduce((acc, developmentPriority) => {
              if (
                  developmentPriority.end_date !== null &&
                  developmentPriority.season_id === currentSeasonId
              ) {
                  acc.push(developmentPriority);
              }
              return acc;
          }, [])
        : [];

    useEffect(() => {
        if (player) {
            player_development_mp_track(
                "View Player Development Priorities and Notes",
                player
            );
        }
    }, [player]);

    const onClose = () => {
        player_development_mp_track("Player Development Panel Closed", player);
        onCloseHandler();
    };

    const isLoading = playerChangeWait;

    return (
        <>
            <PlayerDevelopmentPriorityDialog
                player={player}
                competitionId={competitionId}
                seasonId={currentSeasonId}
                developmentPriority={selectedDevelopmentPriority}
                isOpen={isDevelopmentPriorityDialogOpen}
                updateModeComplete={isDevelopmentPriorityDialogCompleteMode}
                onCloseHandler={() => {
                    setIsDevelopmentPriorityDialogOpen(false);
                    setSelectedDevelopmentPriority(null);
                    setIsDevelopmentPriorityDialogCompleteMode(false);
                }}
                outcomes={playerDevelopmentPriorityOutcomesValues}
            />
            <PlayerDevelopmentSessionDialog
                player={player}
                developmentPriorities={playerDevelopmentPriorities}
                priorityId={selectedDevelopmentPriority?.id}
                session={selectedSession}
                isOpen={isSessionDialogOpen}
                onCloseHandler={() => {
                    setIsSessionDialogOpen(false);
                    setSelectedDevelopmentPriority(null);
                }}
                sessionTypes={playerDevelopmentSessionTypesValues}
            />
            <SlideInPanel
                isOpen={isOpen}
                isLoading={isLoading}
                onClose={onClose}
                targetWidth={targetWidth}
                side="right"
            >
                {confirmedPlayerId && (
                    <div className={classes.slide_in_content}>
                        <div className={classes.player_header_container}>
                            <PlayerHeader
                                player={{
                                    ...player,
                                }}
                                narrowLayout={targetWidth < 700}
                                showContractDetails={false}
                                performanceViewContext={{
                                    competitionId: competitionId,
                                    seasonId: currentSeasonId,
                                }}
                            />
                        </div>
                        <div
                            className={
                                classes.development_priorities_section_container
                            }
                        >
                            <OverheadTitleSection
                                title={"Development Priorities"}
                            ></OverheadTitleSection>
                            <div
                                className={
                                    classes.development_priorities_container
                                }
                            >
                                {canViewPlayerDevelopment &&
                                    activeDevelopmentPriorities.map(
                                        (developmentPriority) => (
                                            <ExpandableContainer
                                                key={developmentPriority.id}
                                                fast={true}
                                                isExpanded={
                                                    !filteredDevelopmentPriority ||
                                                    developmentPriority.id ===
                                                        filteredDevelopmentPriority.id
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.development_priority_container
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.development_priority_content_container
                                                        }
                                                    >
                                                        <PlayerDevelopmentPriorityView
                                                            developmentPriority={
                                                                developmentPriority
                                                            }
                                                            isSelected={
                                                                filteredDevelopmentPriority &&
                                                                developmentPriority &&
                                                                filteredDevelopmentPriority.id ===
                                                                    developmentPriority.id
                                                            }
                                                            sessions={
                                                                playerDevelopmentSessionsByPriority
                                                                    ? playerDevelopmentSessionsByPriority[
                                                                          developmentPriority
                                                                              .id
                                                                      ]
                                                                    : []
                                                            }
                                                            selectClickHandler={() => {
                                                                let finalDevelopmentPriority =
                                                                    developmentPriority;
                                                                setFilteredDevelopmentPriority(
                                                                    (
                                                                        currentDevelopmentPriority
                                                                    ) => {
                                                                        if (
                                                                            !currentDevelopmentPriority ||
                                                                            currentDevelopmentPriority.id !==
                                                                                developmentPriority.id
                                                                        ) {
                                                                            return developmentPriority;
                                                                        }
                                                                        finalDevelopmentPriority =
                                                                            null;
                                                                        return null;
                                                                    }
                                                                );
                                                                player_development_mp_track(
                                                                    "Filter Player Development Priority Sessions",
                                                                    player,
                                                                    {
                                                                        developmentpriority_id:
                                                                            finalDevelopmentPriority?.id,
                                                                        title: finalDevelopmentPriority?.title,
                                                                        performance_metric_name:
                                                                            finalDevelopmentPriority?.performance_metric_name,
                                                                    }
                                                                );
                                                            }}
                                                            editClickHandler={
                                                                canAddUpdatePlayerDevelopmentPriorities &&
                                                                (() => {
                                                                    setSelectedDevelopmentPriority(
                                                                        developmentPriority
                                                                    );
                                                                    setIsDevelopmentPriorityDialogOpen(
                                                                        true
                                                                    );
                                                                    player_development_mp_track(
                                                                        "Open Edit Player Development Priority Dialog",
                                                                        player,
                                                                        {
                                                                            developmentpriority_id:
                                                                                developmentPriority.id,
                                                                            title: developmentPriority.title,
                                                                            performance_metric_name:
                                                                                developmentPriority.performance_metric_name,
                                                                        }
                                                                    );
                                                                })
                                                            }
                                                            closeClickHandler={
                                                                canAddUpdatePlayerDevelopmentPriorities &&
                                                                (() => {
                                                                    setSelectedDevelopmentPriority(
                                                                        developmentPriority
                                                                    );
                                                                    setIsDevelopmentPriorityDialogCompleteMode(
                                                                        true
                                                                    );
                                                                    setIsDevelopmentPriorityDialogOpen(
                                                                        true
                                                                    );
                                                                    player_development_mp_track(
                                                                        "Close Player Development Priority",
                                                                        player,
                                                                        {
                                                                            developmentpriority_id:
                                                                                developmentPriority.id,
                                                                            title: developmentPriority.title,
                                                                            performance_metric_name:
                                                                                developmentPriority.performance_metric_name,
                                                                        }
                                                                    );
                                                                })
                                                            }
                                                            addSessionClickHandler={
                                                                canAddUpdatePlayerDevelopmentSessions &&
                                                                (() => {
                                                                    setSelectedDevelopmentPriority(
                                                                        developmentPriority
                                                                    );
                                                                    setSelectedSession(
                                                                        null
                                                                    );
                                                                    setIsSessionDialogOpen(
                                                                        true
                                                                    );
                                                                    player_development_mp_track(
                                                                        "Open Add Session Dialog",
                                                                        player,
                                                                        {
                                                                            developmentpriority_id:
                                                                                developmentPriority.id,
                                                                            title: developmentPriority.title,
                                                                            performance_metric_name:
                                                                                developmentPriority.performance_metric_name,
                                                                        }
                                                                    );
                                                                })
                                                            }
                                                            addNoteClickHandler={
                                                                false &&
                                                                canAddUpdatePlayerDevelopmentSessions &&
                                                                (() => {
                                                                    //setDevelopmentPriorityForAddNote(
                                                                    //    developmentPriority
                                                                    //);
                                                                    player_development_mp_track(
                                                                        "Open Add Note Dialog",
                                                                        player,
                                                                        {
                                                                            developmentpriority_id:
                                                                                developmentPriority.id,
                                                                            title: developmentPriority.title,
                                                                            performance_metric_name:
                                                                                developmentPriority.performance_metric_name,
                                                                        }
                                                                    );
                                                                })
                                                            }
                                                            expandMetricClickHandler={(
                                                                isExpanded
                                                            ) => {
                                                                player_development_mp_track(
                                                                    `${
                                                                        isExpanded
                                                                            ? "Expand"
                                                                            : "Collapse"
                                                                    } Development Metric Trend`,
                                                                    player,
                                                                    {
                                                                        developmentpriority_id:
                                                                            developmentPriority.id,
                                                                        title: developmentPriority.title,
                                                                        performance_metric_name:
                                                                            developmentPriority.performance_metric_name,
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </ExpandableContainer>
                                        )
                                    )}
                                {(!canViewPlayerDevelopment ||
                                    activeDevelopmentPriorities.length === 0 ||
                                    completedDevelopmentPriorities.length >
                                        0) && (
                                    <div className={classes.empty_container}>
                                        <div className={classes.no_content}>
                                            {!canViewPlayerDevelopment && (
                                                <div
                                                    className={
                                                        classes.no_content_text
                                                    }
                                                >
                                                    You do not have permission
                                                    to view development
                                                    priorities
                                                </div>
                                            )}
                                            {activeDevelopmentPriorities.length ===
                                                0 && (
                                                <div
                                                    className={
                                                        classes.no_content_text
                                                    }
                                                >
                                                    No active development
                                                    priorities
                                                </div>
                                            )}
                                            {completedDevelopmentPriorities.length >
                                                0 && (
                                                <div
                                                    className={
                                                        classes.no_content_text
                                                    }
                                                >
                                                    {completedDevelopmentPriorities.length +
                                                        " development priorit" +
                                                        (completedDevelopmentPriorities.length >
                                                        1
                                                            ? "ies"
                                                            : "y") +
                                                        " completed this season"}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {canAddUpdatePlayerDevelopmentPriorities && (
                                    <div
                                        className={
                                            classes.add_development_priorities_controls_container
                                        }
                                    >
                                        <IconButton
                                            label={"Priority"}
                                            materialIcon={"add_box"}
                                            onClick={() => {
                                                setIsDevelopmentPriorityDialogOpen(
                                                    true
                                                );
                                                player_development_mp_track(
                                                    "Open Post Player Development Priority Dialog",
                                                    player
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <OverheadTitleSection
                            title={"Sessions"}
                        ></OverheadTitleSection>
                        <div className={classes.development_sessions_container}>
                            {canViewPlayerDevelopment &&
                                playerDevelopmentSessions &&
                                playerDevelopmentSessions.map((session) => {
                                    const sessionHeaderElements = [
                                        shortDateString(session.date),
                                        session.interaction_type.label,
                                        session.duration_minutes + " min",
                                    ];
                                    if (session.was_player_instigated) {
                                        sessionHeaderElements.push(
                                            "(player instigated)"
                                        );
                                    }
                                    const isExpanded =
                                        !filteredDevelopmentPriority ||
                                        session.developmentpriority_ids.includes(
                                            filteredDevelopmentPriority.id
                                        );

                                    return (
                                        <ExpandableContainer
                                            key={session.id}
                                            fast={true}
                                            isExpanded={isExpanded}
                                        >
                                            <div
                                                className={
                                                    classes.development_session_container
                                                }
                                            >
                                                <PlayerNoteHeader
                                                    elements={
                                                        sessionHeaderElements
                                                    }
                                                />
                                                <PlayerNoteText
                                                    note={session}
                                                    user={user}
                                                    editClickHandler={() => {
                                                        setSelectedSession(
                                                            session
                                                        );
                                                        setIsSessionDialogOpen(
                                                            true
                                                        );
                                                        player_development_mp_track(
                                                            "Open Edit Session Dialog",
                                                            player,
                                                            {
                                                                session_id:
                                                                    session.id,
                                                                developmentpriority_id:
                                                                    session.developmentpriority_id,
                                                            }
                                                        );
                                                    }}
                                                />
                                                <PlayerNoteOwner
                                                    note={session}
                                                />
                                            </div>
                                        </ExpandableContainer>
                                    );
                                })}

                            {(!canViewPlayerDevelopment ||
                                !playerDevelopmentSessions ||
                                playerDevelopmentSessions.length === 0) && (
                                <div className={classes.empty_container}>
                                    <div className={classes.no_content}>
                                        {!canViewPlayerDevelopment && (
                                            <div
                                                className={
                                                    classes.no_content_text
                                                }
                                            >
                                                You do not have permission to
                                                view development sessions
                                            </div>
                                        )}
                                        {canViewPlayerDevelopment &&
                                            (!playerDevelopmentSessions ||
                                                playerDevelopmentSessions.length ===
                                                    0) && (
                                                <div
                                                    className={
                                                        classes.no_content_text
                                                    }
                                                >
                                                    No development sessions
                                                </div>
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </SlideInPanel>
        </>
    );
}

export default PlayerDevelopmentPanel;
