import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPlayer } from "../store/players-actions";
import ViewNavigator from "../controls/ViewNavigator";
import AppPage from "../layout/AppPage";
import NavButtons from "../controls/buttons/NavButtons";
import PlayerHeader from "../components/player-header/PlayerHeader";
import PlayerPerformanceView from "../components/PlayerPerformanceView";
import useWindowDimensions from "../hooks/use-window-dimensions";
import classes from "./PlayerPage.module.css";

const PlayerPage = ({
    playerId,
    competitionId,
    seasonId,
    metricContext,
    onViewContextChange,
    onMenuOpen,
    backLink,
}) => {
    const dispatch = useDispatch();
    const player = useSelector((state) => state.players.players[playerId]);

    const handleViewContextChange = (context) => {
        if (onViewContextChange) {
            // Add the back link to the context
            onViewContextChange({
                ...context,
                backLink,
            });
        }
    };

    // Layout computations
    const { widthClasses } = useWindowDimensions([
        { min: 1110, max: Infinity }, // Wide layout
        { min: 0, max: 800 }, // Narrow layout
    ]);

    const [layoutConfig, setLayoutConfig] = useState({
        isWide: widthClasses[0]?.matches,
        isNarrow: widthClasses[1]?.matches,
    });

    useEffect(() => {
        setLayoutConfig({
            isWide: widthClasses[0]?.matches,
            isNarrow: widthClasses[1]?.matches,
        });
    }, [widthClasses]);

    useEffect(() => {
        if (playerId && !player) {
            dispatch(fetchPlayer(playerId));
        }
    }, [playerId, player, dispatch]);

    const views = [
        {
            id: "performance",
            label: "Performance",
            shortLabel: "Perf",
            component: (
                <PlayerPerformanceView
                    playerId={playerId}
                    competitionId={competitionId}
                    seasonId={seasonId}
                    metricContext={metricContext}
                    onViewContextChange={handleViewContextChange}
                />
            ),
        },
        // Additional views can be added here
    ];

    return (
        <AppPage>
            <div className={classes.header}>
                <NavButtons
                    openMenu={onMenuOpen}
                    backLink={backLink}
                    darkMode={true}
                />
                <div className={classes.playerHeaderContainer}>
                    <PlayerHeader
                        player={player}
                        showPlayerViewButton={false}
                        wideLayout={layoutConfig.isWide}
                    />
                </div>
            </div>
            <ViewNavigator views={views} preserveViews={false} />
        </AppPage>
    );
};

export default PlayerPage;
