import classes from "./PlayerNote.module.css";
import { timeSince, shortDateString, wasEdited } from "../utils";
import { StatusValueIndicator } from "./indicators";
import SquareButton from "../controls/buttons/SquareButton";
import useAuth from "../hooks/use-auth";

export function PlayerNoteHeader({ elements }) {
    // Display all elements, seperated by a bullet
    return (
        <div className={classes.player_note_header}>
            {elements.map((element, index) => (
                <div key={index} className={classes.header_element}>
                    <div>{element}</div>
                    {index < elements.length - 1 && (
                        <div className={classes.header_spacer}>{" • "}</div>
                    )}
                </div>
            ))}
        </div>
    );
}

export function PlayerNoteText({
    note,
    user,
    editClickHandler,
    forceEditable = false,
}) {
    return (
        <div className={classes.player_note_text}>
            {note.text}
            {editClickHandler &&
                note &&
                ((user && note.owner.email === user.email) ||
                    forceEditable) && (
                    <div className={classes.player_note_controls_container}>
                        <SquareButton
                            label={"edit"}
                            useMaterialIcon={true}
                            smallIcons={true}
                            onClick={() => {
                                editClickHandler(note);
                            }}
                        />
                    </div>
                )}
        </div>
    );
}

export function PlayerNoteOwner({ note }) {
    return (
        <div className={classes.player_note_owner}>
            {(note.owner.last_name !== ""
                ? note.owner.first_name + " " + note.owner.last_name
                : note.owner.email) +
                " • " +
                timeSince(note.created_at) +
                wasEdited(note.created_at, note.modified_at)}
        </div>
    );
}

function PlayerNote({
    note,
    category,
    isStatusUpdate = false,
    isEmptyUpdate = false,
    editClickHandler,
}) {
    const { user } = useAuth();

    const noteHeaderElements = [];

    if (isStatusUpdate && !isEmptyUpdate && note.injury) {
        noteHeaderElements.push(note.injury.end_date ? "Recovered" : "Injured");
        noteHeaderElements.push(note.injury.description);
        noteHeaderElements.push(
            shortDateString(
                note.injury.end_date
                    ? note.injury.end_date
                    : note.injury.start_date
            )
        );
    }

    if (!isStatusUpdate && !note.injury) {
        noteHeaderElements.push(category.name);
    }

    if (!isStatusUpdate && note.injury) {
        noteHeaderElements.push(
            note.is_new_injury
                ? "Injured"
                : note.is_closing_injury
                ? "Recovered"
                : "Injury update"
        );
        noteHeaderElements.push(note.injury.description);

        if (note.is_new_injury || note.is_closing_injury) {
            noteHeaderElements.push(
                shortDateString(
                    note.is_new_injury
                        ? note.injury.start_date
                        : note.injury.end_date
                )
            );
        }
    }

    return (
        <div
            className={
                classes.player_note_container +
                (isStatusUpdate ? " " + classes.status_update : "")
            }
        >
            <div
                className={
                    classes.player_note_content +
                    (isEmptyUpdate ? " " + classes.no_update : "")
                }
            >
                <div className={classes.player_note_details}>
                    {isStatusUpdate && (
                        <div className={classes.player_note_title}>
                            {category.name}
                        </div>
                    )}
                    {(!isStatusUpdate || (!isEmptyUpdate && note.injury)) && (
                        <PlayerNoteHeader elements={noteHeaderElements} />
                    )}
                    <PlayerNoteText
                        note={isEmptyUpdate ? { text: "No update" } : note}
                        user={!isEmptyUpdate && user}
                        editClickHandler={editClickHandler}
                    />
                    {!isEmptyUpdate && <PlayerNoteOwner note={note} />}
                </div>
                {((!isEmptyUpdate && note.status_value) ||
                    (isStatusUpdate && category.default_status_value)) && (
                    <div className={classes.player_status_value}>
                        <StatusValueIndicator
                            statusValue={
                                !isEmptyUpdate
                                    ? note.status_value
                                    : category.default_status_value
                            }
                            small={!isStatusUpdate}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default PlayerNote;
