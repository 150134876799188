import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import { fetchCompetition } from "../store/competitions-actions.js";
import { fetchSeasons } from "../store/seasons-actions.js";
import {
    fetchPlayer,
    fetchPlayersSeasonsDatastash,
} from "../store/players-actions.js";

import MainContent from "../layout/MainContent";
import OverheadTitleSection from "../layout/OverheadTitleSection";
import PlayerSeasonsSummary from "./PlayerSeasonsSummary";
import PlayerSeasonPerformanceView from "./player-season-performance-view/PlayerSeasonPerformanceView";
import PlayerScoutingReports from "./PlayerScoutingReports";
import useAuth from "../hooks/use-auth";
import { getPlayerSeasonAge } from "../utils";
import useElementDimensions from "../hooks/use-element-dimensions";

import classes from "./PlayerPerformanceView.module.css";
import { mp_track } from "../mixpanel";

const player_performance_view_mp_track = (
    event,
    player,
    competition,
    season,
    properties = null
) => {
    properties = {
        ...properties,
        player_id: player.id,
        player_name: player.known_name,
        competition_id: competition.id,
        competition_name: competition.display_name,
        season_id: season.id,
        season_name: season.name,
    };

    mp_track(event, properties);
};

const PlayerPerformanceView = ({
    playerId,
    competitionId,
    seasonId,
    metricContext,
    onViewContextChange,
}) => {
    const dispatch = useDispatch();

    const { checkPermission } = useAuth();
    const canViewScoutingReports = checkPermission(
        "core.can_view_rinknet_reports"
    );

    const widthRangesConfig = useMemo(
        () => [
            { min: 1110, max: Infinity }, // Wide layout
            { min: 0, max: 800 }, // Narrow layout
        ],
        []
    );
    const { elementRef, widthClasses } =
        useElementDimensions(widthRangesConfig);

    const findIsWide = useCallback(
        () => widthClasses[0]?.matches,
        [widthClasses]
    );
    const findIsNarrow = useCallback(
        () => widthClasses[1]?.matches,
        [widthClasses]
    );

    const [wideLayout, setWideLayout] = useState(findIsWide);
    const [narrowLayout, setNarrowLayout] = useState(findIsNarrow);

    useEffect(() => {
        const isWide = findIsWide();
        if (isWide !== wideLayout) {
            setWideLayout(isWide);
        }
    }, [findIsWide, wideLayout]);

    useEffect(() => {
        const isNarrow = findIsNarrow();
        if (isNarrow !== narrowLayout) {
            setNarrowLayout(isNarrow);
        }
    }, [findIsNarrow, narrowLayout]);

    const [seasonSummaryLoading, setSeasonSummaryLoading] = useState(false);
    const [seasonPerformanceViewLoading, setSeasonPerformanceViewLoading] =
        useState(false);
    const [scoutingReportsLoading, setScoutingReportsLoading] = useState(false);
    const [availableReports, setAvailableReports] = useState([]);
    const [selectedReportDate, setSelectedReportDate] = useState(null);
    const [selectedReportId, setSelectedReportId] = useState(null);

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );

    const [selectedSeasonId, setSelectedSeasonId] = useState(null);
    const [selectedCompetitionId, setSelectedCompetitionId] = useState(null);

    const competitions = useSelector(
        (state) => state.competitions.competitions
    );

    const seasons = useSelector((state) => state.seasons.seasons);

    const player_datastashes = useSelector(
        (state) => state.players.datastashes[playerId]
    );
    const player = useSelector((state) => state.players.players[playerId]);
    const players_sds = useSelector(
        (state) => state.players.seasondatastashes[playerId]
    );
    const career_performance_summary = useMemo(
        () =>
            playerId &&
            player_datastashes &&
            player_datastashes.career_performance_summary,
        [playerId, player_datastashes]
    );

    useEffect(() => {
        if (career_performance_summary) {
            // Find the set of seasons the player was in, based on the seasons
            // found in each competition of the career_performance_summary.
            const seasons = career_performance_summary.competitions.reduce(
                (accumulator, competition) => {
                    competition.seasons.forEach((season) => {
                        if (!accumulator.includes(season.season_id)) {
                            accumulator.push(season.season_id);
                        }
                    });
                    return accumulator;
                },
                []
            );

            // Find seasons that are missing in the player's datastashes
            const missingSeasons = seasons.filter(
                (season) =>
                    !players_sds ||
                    !players_sds.position_metrics_series ||
                    !players_sds.position_metrics_series[season]
            );

            // Fetch the missing seasons
            if (missingSeasons.length > 0) {
                dispatch(
                    fetchPlayersSeasonsDatastash(
                        "position_metrics_series",
                        [playerId],
                        missingSeasons
                    )
                );
            }
        }
    }, [playerId, players_sds, career_performance_summary, dispatch]);

    // The active season is the season that is currently being viewed.
    // It is determined by the seasonId prop, or if that is not provided,
    // by the the most recent season in any competition (other than tournaments)
    // in the career_performance_summary datastash.
    const activeSeasonId = useMemo(() => {
        return (
            selectedSeasonId ||
            seasonId ||
            (seasons &&
                career_performance_summary &&
                career_performance_summary.competitions &&
                // Find season names that are not tournament where the player played
                // in the confirmedCompetitionId.
                career_performance_summary.competitions
                    .reduce(
                        (accumulator, competition) => {
                            if (
                                // Filter out all tournaments
                                competitions[competition.id] &&
                                competitions[competition.id]
                                    .competition_type !== "tournament"
                            ) {
                                accumulator.push(
                                    // The most recent season in the competition
                                    [...competition.seasons].sort(
                                        (a, b) => b.season_id - a.season_id
                                    )[0].season_id
                                );
                            }
                            return accumulator;
                        },
                        []
                        // Sort by id, so that the first season is the most recent season
                        // in any competition (other than tournaments) in the
                        // career_performance_summary datastash.
                    )
                    .sort((a, b) => b - a)[0]) ||
            currentSeasonId
        );
    }, [
        selectedSeasonId,
        seasonId,
        seasons,
        career_performance_summary,
        competitions,
        currentSeasonId,
    ]);

    // The active competition is the competition that is currently being viewed.
    // It is determined by the competitionId prop, or if that is not provided,
    // by the competitions in the player's career_performance_summary datastash.
    // that the player was in during the activeSeasonId. If there are multiple
    // competitions in the player's career_performance_summary datastash for the
    // activeSeasonId, then the order will be NHL if present, AHL if present,
    // and then the first competition in the list that is not a tournament.
    const activeCompetitionId = useMemo(() => {
        return (
            selectedCompetitionId ||
            competitionId ||
            (career_performance_summary &&
                career_performance_summary.competitions &&
                activeSeasonId &&
                // Find competitions that are not tournament where the player played
                // in the activeSeasonId.
                career_performance_summary.competitions
                    .reduce(
                        (accumulator, competition) => {
                            if (
                                // Filter all season objects season_id on the activeSeasonId
                                competition.seasons.filter(
                                    (season) =>
                                        season.season_id === activeSeasonId
                                ).length > 0 &&
                                // Filter out all tournaments
                                competitions[competition.id] &&
                                competitions[competition.id]
                                    .competition_type !== "tournament"
                            ) {
                                accumulator.push(competition.id);
                            }
                            return accumulator;
                        },
                        []
                        // Sort by id, so that the first competition is the NHL, if present,
                        // then the AHL, if present, and then the first competition in the
                        // list that is not a tournament.
                    )
                    .sort((a, b) => a - b)[0])
        );
    }, [
        selectedCompetitionId,
        competitionId,
        career_performance_summary,
        activeSeasonId,
        competitions,
    ]);

    const competition = competitions[activeCompetitionId];
    const season = seasons[activeSeasonId];

    const handleSeasonChange = useCallback((seasonId) => {
        setSelectedSeasonId(seasonId);
    }, []);

    const handleMetricContextChange = useCallback((metricContext) => {
        setActiveMetricContext(metricContext);
    }, []);

    const handleSeasonCompetitionsChange = useCallback(
        (seasonId, competitionId) => {
            setSelectedSeasonId(seasonId);
            setSelectedCompetitionId(competitionId);
        },
        []
    );

    useEffect(() => {
        if (player && competition && season) {
            player_performance_view_mp_track(
                "View Player Performance Page",
                player,
                competition,
                season
            );
        }
    }, [player, competition, season]);

    const [activeMetricContext, setActiveMetricContext] = useState(
        metricContext
            ? metricContext
            : {
                  metricStack: null,
                  selectedMetric: null,
              }
    );

    useEffect(() => {
        activeCompetitionId &&
            activeSeasonId &&
            onViewContextChange &&
            onViewContextChange({
                playerId,
                competitionId: activeCompetitionId,
                seasonId: activeSeasonId,
                metricContext: activeMetricContext,
                selectedReportId,
            });
    }, [
        playerId,
        activeCompetitionId,
        activeSeasonId,
        activeMetricContext,
        selectedReportId,
        onViewContextChange,
    ]);

    useEffect(() => {
        if (playerId && !player) {
            dispatch(fetchPlayer(playerId));
        }
    }, [playerId, player, dispatch]);

    useEffect(() => {
        setSelectedReportDate(null);
        setSelectedReportId(null);
    }, [playerId]);

    useEffect(() => {
        if (activeCompetitionId && !competition) {
            dispatch(fetchCompetition(activeCompetitionId));
        }
    }, [activeCompetitionId, competition, dispatch]);

    useEffect(() => {
        if (activeSeasonId && !season) {
            dispatch(fetchSeasons());
        }
    }, [activeSeasonId, season, dispatch]);

    useEffect(() => {
        if (
            player &&
            !seasonSummaryLoading &&
            !seasonPerformanceViewLoading &&
            !scoutingReportsLoading
        ) {
            dispatch(uiActions.hideLoader());
        }
    }, [
        player,
        seasonSummaryLoading,
        seasonPerformanceViewLoading,
        scoutingReportsLoading,
        dispatch,
    ]);

    const onAvailableReportsLoaded = useCallback((reports) => {
        setAvailableReports(
            reports.map((report) => {
                return {
                    ...report,
                    date: new Date(report.date + "T00:00:00"),
                };
            })
        );
    }, []);

    const onReportSelectedHandler = useCallback(
        (date, report_id, trigger_event) => {
            // Find the selected reprot in the available reports
            // If only the date is provided, find the first report with that date
            // If the report_id is provided, find the report with that id
            let report = null;
            if (report_id) {
                report = availableReports.find(
                    (report) => report.id === report_id
                );
            } else {
                report = availableReports.find(
                    (report) => report.date.getTime() === date.getTime()
                );
            }

            // Set the selected report date and id.
            // If no report was found, set both to null
            if (report) {
                setSelectedReportDate(report.date);
                setSelectedReportId(report.id);

                if (trigger_event) {
                    player_performance_view_mp_track(
                        "Player Scouting Report Selection - From Chart",
                        player,
                        competition,
                        season,
                        {
                            report_id: report.id,
                            report_date: report.date,
                        }
                    );
                }
            } else {
                setSelectedReportDate(null);
                setSelectedReportId(null);
            }
        },
        [availableReports, player, competition, season]
    );

    const highlightDates = useMemo(() => {
        return availableReports.map((report) => report.date);
    }, [availableReports]);

    const handleHighlightDateSelected = useCallback(
        (date) => onReportSelectedHandler(date, null, true),
        [onReportSelectedHandler]
    );

    return (
        <MainContent>
            <div
                ref={elementRef}
                className={
                    classes.page_content +
                    (narrowLayout ? " " + classes.narrow : "")
                }
            >
                <div
                    className={
                        classes.page_section +
                        " " +
                        classes.career_summary +
                        (narrowLayout ? " " + classes.narrow : "")
                    }
                >
                    <OverheadTitleSection title={"Career Summary"}>
                        <PlayerSeasonsSummary
                            playerId={playerId}
                            selectedSeasonId={activeSeasonId}
                            selectedCompetitionId={activeCompetitionId}
                            onSeasonCompetitionClick={
                                handleSeasonCompetitionsChange
                            }
                            narrowLayout={true}
                            isLoadingHandler={setSeasonSummaryLoading}
                        />
                    </OverheadTitleSection>
                </div>
                {competition && season && (
                    <div
                        className={
                            classes.page_section +
                            " " +
                            classes.season_drilldown +
                            (narrowLayout ? " " + classes.narrow : "")
                        }
                    >
                        <OverheadTitleSection
                            title={
                                competition.display_name +
                                " " +
                                season.name +
                                " Performance (" +
                                getPlayerSeasonAge(player, season) +
                                "yo)"
                            }
                        ></OverheadTitleSection>
                        <PlayerSeasonPerformanceView
                            playerId={playerId}
                            seasonId={activeSeasonId}
                            competitionId={competitionId}
                            metricContext={activeMetricContext}
                            peersData={null}
                            highlightDates={highlightDates}
                            selectedHighlightDate={selectedReportDate}
                            onHighlightDateSelected={
                                handleHighlightDateSelected
                            }
                            onSeasonSelectionHandler={handleSeasonChange}
                            onMetricContextChange={handleMetricContextChange}
                            isLoadingHandler={setSeasonPerformanceViewLoading}
                            wideLayout={wideLayout}
                            careerMode={true}
                        />
                    </div>
                )}
                {canViewScoutingReports && (
                    <div
                        className={
                            classes.page_section +
                            " " +
                            classes.scouting_reports +
                            (narrowLayout ? " " + classes.narrow : "")
                        }
                    >
                        <OverheadTitleSection title="Scouting Reports">
                            <PlayerScoutingReports
                                playerId={playerId}
                                isLoadingHandler={setScoutingReportsLoading}
                                selectedReportId={selectedReportId}
                                onReportChange={(report) => {
                                    onReportSelectedHandler(
                                        new Date(report.date + "T00:00:00"),
                                        report.rinknet_id
                                    );
                                }}
                                onReportListChange={onAvailableReportsLoaded}
                            />
                        </OverheadTitleSection>
                    </div>
                )}
            </div>
        </MainContent>
    );
};

export default PlayerPerformanceView;
