import { createSlice } from "@reduxjs/toolkit";

const teamsSlice = createSlice({
    name: "teams",
    initialState: {
        teams: {},
        datastashes: {},
        seasondatastashes: {},
        reserveListPlayerIds: {},
        deadCapCommitments: {},
        depthCharts: {},
    },
    reducers: {
        updateTeam(state, action) {
            const team = action.payload;
            state.teams[team.id] = team;
        },
        updateTeams: (state, action) => {
            action.payload.forEach((team) => {
                state.teams[team.id] = team;
            });
        },
        updateTeamDatastash(state, action) {
            const { teamId, datastashName, data } = action.payload;
            if (!state.datastashes[teamId]) {
                state.datastashes[teamId] = {};
            }
            state.datastashes[teamId][datastashName] = data;
        },
        updateTeamsDatastash(state, action) {
            const { datastashName, data } = action.payload;

            data.forEach((datastash) => {
                const { team_id: teamId, data } = datastash;
                if (!state.datastashes[teamId]) {
                    state.datastashes[teamId] = {};
                }
                state.datastashes[teamId][datastashName] = data;
            });
        },
        updateTeamSeasonDatastash(state, action) {
            const { teamId, datastashName, seasonId, data } = action.payload;
            if (!state.seasondatastashes[teamId]) {
                state.seasondatastashes[teamId] = {};
            }
            if (!state.seasondatastashes[teamId][datastashName]) {
                state.seasondatastashes[teamId][datastashName] = {};
            }
            state.seasondatastashes[teamId][datastashName][seasonId] = data;
        },
        updateTeamsSeasonsDatastash(state, action) {
            const { datastashName, data } = action.payload;

            data.forEach((datastash) => {
                const {
                    team_id: teamId,
                    season_id: seasonId,
                    data,
                } = datastash;
                if (!state.seasondatastashes[teamId]) {
                    state.seasondatastashes[teamId] = {};
                }
                if (!state.seasondatastashes[teamId][datastashName]) {
                    state.seasondatastashes[teamId][datastashName] = {};
                }
                state.seasondatastashes[teamId][datastashName][seasonId] = data;
            });
        },
        updateTeamReserveListPlayerIds(state, action) {
            const { teamId, data } = action.payload;
            if (!state.reserveListPlayerIds[teamId]) {
                state.reserveListPlayerIds[teamId] = {};
            }
            state.reserveListPlayerIds[teamId] = data.map(
                (player) => player.id
            );
        },
        updateTeamDeadCapCommitments(state, action) {
            const teamId = action.payload.team_id;
            const deadCapCommitments = action.payload.dead_cap_commitments;
            state.deadCapCommitments[teamId] = deadCapCommitments;
        },
        updateTeamDepthChart(state, action) {
            const teamId = action.payload.teamId;
            const depthChart = action.payload.data;
            state.depthCharts[teamId] = depthChart;
        },
    },
});

export const teamsActions = teamsSlice.actions;

export default teamsSlice;
