export const get_cookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                );
                break;
            }
        }
    }
    return cookieValue;
};

export const getOrdinal = (num) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = num % 100;
    return (
        num + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])
    );
};

export const fetchData = async (
    url,
    error_message,
    method = "GET",
    body = null
) => {
    const csrftoken = get_cookie("csrftoken");

    // Prepare the headers and body for the request
    const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
    };

    // Configure the request options
    const requestOptions = {
        method: method,
        headers: headers,
        credentials: "include", // Needed for cookies, credentials include,exclude,same-origin
    };

    // Add the body to the request if method is POST, PATCH or PUT
    // and body is not null
    if (["POST", "PATCH", "PUT"].includes(method) && body) {
        requestOptions.body = JSON.stringify(body);
    }

    // Perform the fetch request
    const response = await fetch(url, requestOptions);

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
        throw new Error(error_message);
    }

    // Parse and return the response body
    const data = await response.json();
    return data;
};

export const get_age_at_date = (birth_date, target_date) => {
    let age = target_date.getFullYear() - birth_date.getFullYear();
    if (
        target_date.getMonth() < birth_date.getMonth() ||
        (target_date.getMonth() === birth_date.getMonth() &&
            target_date.getDate() < birth_date.getDate())
    ) {
        age -= 1;
    }
    return age;
};

export const formatValue = (value, precision, isPercentage, isSeconds) => {
    return value !== null && value !== undefined
        ? typeof value === "number" && !isNaN(value)
            ? isPercentage
                ? (value * 100.0).toFixed(precision) + "%"
                : isSeconds
                ? formatToiSeconds(value)
                : value.toFixed(precision)
            : value
        : "-";
};

export const format_full_birth_location = (player) => {
    return [player?.birth_city, player?.birth_province, player?.birth_country]
        .filter(Boolean)
        .join(", ");
};

export const getPrimaryPosition = (position) => {
    /*  Function to get the primary position (i.e. F, D or G) associated to a
        given granular player position (i.e. LW, C, RW, LD, RD).

        :param str position: the granular player position to map.
        :returns str: the associated primary position.
    */
    let primary_position = position;

    if (["LW", "C", "RW"].includes(position)) {
        primary_position = "F";
    } else if (["LD", "RD", "D"].includes(position)) {
        primary_position = "D";
    }

    return primary_position;
};

export const longestWord = (inputString) => {
    // Split the string into an array of words
    const words = inputString.split(/[\s-]/);

    // Sort the array by word length, in descending order
    words.sort((a, b) => b.length - a.length);

    // Return the first word (the longest one) from the sorted array
    return words[0];
};

export const getMetricContent = (metricName, metrics, values) => {
    const metricIdx = metrics && metrics.indexOf(metricName);
    return values && metricIdx !== -1 && values[metricIdx];
};

export const getValuePerformanceTier = (value, thresholds, tiers) => {
    if (!thresholds || !tiers || thresholds.length !== tiers.length + 1) {
        return {
            tier: null,
            tier_max: null,
            tier_min: null,
        };
    }

    const negativeDirectionality = thresholds[1] > thresholds[0];

    return thresholds.reduce(
        (acc, hidx_val, idx) => {
            if (idx > 0) {
                const lidx_val = thresholds[idx - 1];
                const min = negativeDirectionality ? lidx_val : hidx_val;
                const max = negativeDirectionality ? hidx_val : lidx_val;
                if (
                    acc.tier === null &&
                    ((negativeDirectionality && value <= hidx_val) ||
                        (!negativeDirectionality && value >= hidx_val) ||
                        idx === thresholds.length - 1)
                ) {
                    return {
                        tier: tiers[idx - 1],
                        tier_max: max,
                        tier_min: min,
                    };
                }
            }
            return acc;
        },
        {
            tier: null,
            tier_max: null,
            tier_min: null,
        }
    );
};

export const PERCENTILE_TIER_LIMITS = {
    ELITE: 0.9,
    TOP: 0.75,
    HIGH: 0.55,
    MID: 0.3,
};

export const getPerformanceTierFromPercentile = (percentile) => {
    const thresholds = [
        1.0,
        PERCENTILE_TIER_LIMITS.ELITE,
        PERCENTILE_TIER_LIMITS.TOP,
        PERCENTILE_TIER_LIMITS.HIGH,
        PERCENTILE_TIER_LIMITS.MID,
        0.0,
    ];
    const tiers = ["elite", "top", "high", "mid", "low"];
    return getValuePerformanceTier(percentile, thresholds, tiers);
};

export const formatToiSeconds = (toiSeconds) => {
    // Calculate minutes
    const minutes = Math.floor(toiSeconds / 60);

    // Calculate remaining seconds
    const remainingSeconds = Math.floor(toiSeconds % 60);

    // Add leading zeros to seconds if less than 10
    const formattedSeconds =
        remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    // Return the final formatted string
    return `${minutes}:${formattedSeconds}`;
};

export const getPlayerSeasonAge = (player, season) => {
    // Calculate player age on Sept 15th of the first year in the season name.
    // Get July 1st of the first year in the season name.
    const seasonStart = new Date(season?.name.substring(0, 4), 8, 14);

    return get_age_at_date(new Date(player?.birth_date), seasonStart);
};

/*
    The getMagnitudeTicks function generates an array of evenly spaced
    numerical "ticks" or values within a user-specified range, defined by a
    minimum (min) and maximum (max) value.
    The function employs rounding based on the calculated order of magnitude of
    the range, ensuring that the generated ticks align with values that are
    sensible in terms of the order of magnitude, which can result in the first
    tick being lower than the provided min and the last tick being higher than
    the provided max.
    This function is particularly beneficial for use cases like data
    visualization where appropriately spaced and range-covering axis labels or
    ticks are needed.

    Parameters
    min (Number): The minimum value of the range from which to generate ticks.
        It should be a finite number and should be less than the max parameter.

    max (Number): The maximum value of the range from which to generate ticks.
        It should be a finite number and should be greater than the min
        parameter.

    steps (Number): The number of divisions or steps in the range. The function
        will generate steps + 1 ticks, including both the min and max. steps
        should be a positive integer.

    Returns
    Array (Number): Returns an array of numbers representing the ticks. The
        length of the array is steps + 1. The array starts from a value equal
        to or lower than the min parameter and ends at a value equal to or
        higher than the max parameter. The exact values are determined by
        dividing the range into equal parts according to the steps parameter.

    Example
        const ticks = getMagnitudeTicks(0, 100, 5);
        console.log(ticks);
        // Output: [0, 20, 40, 60, 80, 100]
        In this example, the function generates 6 ticks (5 steps) evenly
        distributed from 0 to 100.

    Notes
    The function handles the formatting of decimal numbers and removes trailing
    zeroes.
*/
export const getMagnitudeTicks = (min, max, steps) => {
    const result = [];

    if (isFinite(min) && isFinite(max) && min < max && steps > 0) {
        let magnitude = Math.pow(10, Math.floor(Math.log10(max - min)));
        let floor = Math.floor(min / magnitude) * magnitude;
        let ceiling = Math.ceil(max / magnitude) * magnitude;

        // Tighten a bit further if the range / magnitude ratio is small.
        if ((ceiling - floor) / magnitude < 5) {
            magnitude = Math.pow(10, Math.floor(Math.log10(max - min))) / 2;
            floor = Math.floor(min / magnitude) * magnitude;
            ceiling = Math.ceil(max / magnitude) * magnitude;
        }

        const step_size = (ceiling - floor) / steps;

        for (let i = 0; i <= steps; i++) {
            const value = floor + i * step_size;

            const formatted_value = Number(value.toFixed(3))
                .toString()
                .replace(/(\.\d+?)0+$/, "$1")
                .replace(/\.$/, "");
            result.push(parseFloat(formatted_value));
        }
    }

    return result;
};

/*
    The getMinutesTicks function generates an array of evenly spaced numerical
    "ticks" or values within a user-specified range, defined by a minimum (min)
    and maximum (max) value.
    The provided values are in seconds, and the function will return ticks that
    ensure the range is covered, and each tick will fall on a minute boundary.
*/
export const getToiMinutesTicks = (min, max, steps, autoScale = false) => {
    const result = [];

    if (isFinite(min) && isFinite(max)) {
        let minutesPerStep = 1.0;
        let minMinutes = Math.floor(min / 60);

        // If the autoScale parameter is set to true, the function will pin the
        // highest tick to the minute boundary above the max value. Otherwise, the
        // lowest tick will be the minute boundary below the min value.

        if (autoScale) {
            const maxMinutes = Math.ceil(max / 60);
            const targetMinMinutes = Math.floor(min / 60);
            while (maxMinutes - minutesPerStep * steps > targetMinMinutes) {
                minutesPerStep += 1.0;
            }
            minMinutes = maxMinutes - minutesPerStep * steps;
        } else {
            const targetMaxMinutes = Math.ceil(max / 60);
            while (minMinutes + minutesPerStep * steps < targetMaxMinutes) {
                minutesPerStep += 1.0;
            }
        }

        for (let i = 0; i <= steps; i++) {
            result.push((minMinutes + i * minutesPerStep) * 60.0);
        }
    }

    return result;
};

const ONE_MILLION = 1000000.0;

export const formatSalaryString = (salary, short = true) => {
    let salary_string = "";

    if (salary >= ONE_MILLION) {
        salary_string = (salary / ONE_MILLION).toFixed(2) + (short ? "" : "M");
    } else {
        salary_string = (salary / 1000.0).toFixed(0) + "k";
    }

    return salary_string;
};

export const getPlayerFreeAgencySeasonYear = (player) => {
    // Get the current date and time. The current season year is the current
    // year if we are past July 1st, otherwise it is the previous year.
    const today = new Date();
    const currentSeasonYear =
        today.getMonth() >= 6 ? today.getFullYear() : today.getFullYear() - 1;

    // Add player.contract_years and player.contract_extension to the current
    // season year to get the free agency season year.
    // Both values can be null, in which case they should count as zero.
    const freeAgencySeasonYear =
        player &&
        currentSeasonYear +
            (player.contract_years || 0) +
            (player.contract_extension || 0);

    return freeAgencySeasonYear;
};

export function formatDateMonthDay(dateString) {
    const [, month, day] = dateString.split("-");
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    // Remove leading zero by parsing as integer and converting back to string
    const formattedDay = parseInt(day, 10).toString();
    return `${months[parseInt(month, 10) - 1]}\u00A0${formattedDay}`;
}

/*
    This function takes a date string and returns a short string representing
    the date. If the date is within the past year, the string is formatted as
    "MMM DD", where MMM is the three-letter month abbreviation and DD is the
    day of the month. If the date is not within the past year, the string is
    formatted as "MMM DD, YYYY", where YYYY is the four-digit year.

    The date is assumed to be in the format "YYYY-MM-DD". The date string is
    taken as is, regardless of the time zone.
*/
export function shortDateString(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const oneYearAgo = new Date(
        now.getFullYear() - 1,
        now.getMonth(),
        now.getDate()
    );
    const withinPastYear = date > oneYearAgo;
    const formattedDate =
        formatDateMonthDay(dateString) +
        (withinPastYear ? "" : `,\u00A0${date.getFullYear()}`);
    return formattedDate;
}

/*
    This function takes a date string and returns a string representing the
    timeelapsed between that date and now. The string is formatted as follows:
    - If the time elapsed is less than 60 minutes, the string is formatted as
        "Xm", where X is the number of minutes.
    - If the time elapsed is less than 24 hours, the string is formatted as
        "Xh", where X is the number of hours.
    - If the time elapsed is less than 7 days, the string is formatted as
        "Xd", where X is the number of days.
    - If the time elapsed is less than 6 weeks, the string is formatted as
        "Xw", where X is the number of weeks.
    - If the time elapsed is less than 12 months, the string is formatted as
        "X month(s)", where X is the number of months.
    - If the time elapsed is greater than or equal to 12 months, the string is
        formatted as "Xy", where X is the number of years.
*/
export function timeSince(
    past_date,
    granularity = "minute",
    maxGranularity = "year"
) {
    let pastDate;
    const now = new Date();

    // Check if the date string is a date-only string
    if (/^\d{4}-\d{2}-\d{2}$/.test(past_date)) {
        // Date-only string: Manually parse to ensure local time
        const [year, month, day] = past_date.split('-').map(Number);
        pastDate = new Date(year, month - 1, day);
    } else {
        // Full timestamp: Parse directly
        pastDate = new Date(past_date);

        // Check for invalid date
        if (isNaN(pastDate.getTime())) {
            throw new Error('Invalid date format');
        }
    }

    // Function to calculate the difference in days
    const getDayDifference = (date1, date2) => {
        // Reset the time part to get the start of the day in local time
        const d1 = new Date(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate()
        );
        const d2 = new Date(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate()
        );

        // Calculate the difference and convert to days
        return Math.round((d2 - d1) / (1000 * 60 * 60 * 24));
    };

    const diff = now - pastDate;
    const dayDiff = getDayDifference(pastDate, now);

    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(diff / 3600000);
    const days = granularity === "day" ? dayDiff : Math.floor(diff / 86400000);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30.44); // Approximation
    const years = Math.floor(days / 365.25); // Approximation

    const granularityOrder = ["minute", "hour", "day", "week", "month", "year"];
    const maxGranularityIndex = granularityOrder.indexOf(maxGranularity);

    let timeElapsed = "";

    if (granularity === "day") {
        if (days === 0) {
            return "td";
        }
        // Use maxGranularity for display, but ensure at least days are shown
        if (maxGranularityIndex >= 5 && years > 0) {
            timeElapsed = `${years}y`;
        } else if (maxGranularityIndex >= 4 && months > 0) {
            timeElapsed = `${months}mo`;
        } else if (maxGranularityIndex >= 3 && weeks > 0) {
            timeElapsed = `${weeks}w`;
        } else {
            timeElapsed = `${days}d`;
        }
    } else {
        if (maxGranularityIndex >= 5 && years > 0) {
            timeElapsed = `${years}y`;
        } else if (maxGranularityIndex >= 4 && months > 0) {
            timeElapsed = `${months}mo`;
        } else if (maxGranularityIndex >= 3 && weeks > 0) {
            timeElapsed = `${weeks}w`;
        } else if (days > 0) {
            timeElapsed = `${days}d`;
        } else if (hours > 0) {
            timeElapsed = `${hours}h`;
        } else {
            timeElapsed = `${minutes}m`;
        }
    }

    return timeElapsed;
}

export function getCurrentDateString() {
    // Return the current local date in the format "YYYY-MM-DD"
    return new Date().toLocaleDateString('en-CA');
}

/*
    This function takes two dates and returns a string indicating whether the
    second date is different from the first date. If the second date is
    more than 0.5 seconds later than the first one, the string is formatted as
    "(edited)".
*/
export function wasEdited(created_at, modified_at) {
    const createdAt = new Date(created_at);
    const modifiedAt = new Date(modified_at);
    const diff = modifiedAt - createdAt;
    return modified_at && diff > 500 ? " (edited)" : "";
}
