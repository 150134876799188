import classes from "./MainBox.module.css";

function MainBox({
    children,
    bgColor,
    clearOnNarrow = false,
    fillHeight = false,
    isSection=false,
}) {
    return (
        <div
            className={
                classes.main_box +
                (isSection ? " " + classes.section : "") +
                (fillHeight ? " " + classes.fill_height : "") +
                (clearOnNarrow ? " " + classes.clear_on_narrow : "")
            }
            style={bgColor && { backgroundColor: bgColor }}
        >
            {children}
        </div>
    );
}

export default MainBox;
