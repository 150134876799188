import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeam, fetchTeamDatastash } from "../store/teams-actions";
import { fetchSeasonPlayerInteractions } from "../store/players-actions";
import { fetchCoaches } from "../store/users-actions";
import useAuth from "../hooks/use-auth";
import { uiActions } from "../store/ui-slice";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import CompressiblePanel from "../layout/CompressiblePanel";
import PlayerInteractionsPanel from "../components/player-side-panels/PlayerInteractionsPanel";
import playersSlice from "../store/players-slice";
import TieredTeamPlayers from "../components/TieredTeamPlayers";
import useTeamTieredPlayers from "../hooks/use-team-tiered-players";
import { EMPTY_VIEW_CONTEXT } from "../hooks/use-cross-page-view-context";
import IconButton from "../controls/buttons/IconButton";
import {
    PlayerInteractionDialog,
    player_interactions_mp_track,
} from "../components/PlayerInteractionsDialogs";
import { usePlayerConversationTypes } from "../hooks/enum-helpers";
import TeamPlayerInteractionsLegend from "../components/legends/TeamPlayerInteractionsLegend";
import NavigationBar, { NavigationSetting } from "../controls/NavigationBar";
import { OptionRadioBar } from "../controls/OptionRadioBar";

import classes from "./TeamPlayerInteractions.module.css";
import { ToneIndicator } from "../components/indicators";
import { timeSince } from "../utils";
import { mp_track } from "../mixpanel";

const team_player_interactions_mp_track = (
    team,
    tier,
    event,
    properties = null
) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
        tier: tier,
    };

    mp_track(event, properties, "player_interactions");
};

const subTiers = {
    NHL: {
        label: "NHL Active Roster",
    },
    OFF_ROSTER: {
        label: "Injured Reserve",
    },
    AHL: {
        label: "AHL Roster",
    },
    JCE: {
        label: "Juniors, College & European Players",
    },
};

const PlayerInteractionsTile = ({ player, tileData, position, tier }) => {
    const recentInteractions = tileData.season_interactions
        ? tileData.season_interactions.slice(1, 5).reverse()
        : [];

    const mostRecentInteraction = tileData.season_interactions
        ? tileData.season_interactions[0]
        : null;
    const mostRecentInteractionOwnerInitials = mostRecentInteraction
        ? `${mostRecentInteraction.owner.first_name[0]}${mostRecentInteraction.owner.last_name[0]}`
        : "";

    return (
        <div className={classes.interactions_tile_decoration}>
            {tileData.season_interactions && (
                <>
                    <div className={classes.recent_interactions_container}>
                        {recentInteractions.map((interaction, index) => {
                            const ownerInitials = `${interaction.owner.first_name[0]}${interaction.owner.last_name[0]}`;

                            return (
                                <div
                                    key={index}
                                    className={classes.recent_interaction}
                                >
                                    <div
                                        className={
                                            classes.recent_interaction_tooltip
                                        }
                                    >
                                        {`${
                                            interaction.date
                                        }: ${interaction.text.substring(
                                            0,
                                            50
                                        )}...`}
                                    </div>
                                    <ToneIndicator
                                        tone={interaction.tone}
                                        label={ownerInitials}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {mostRecentInteraction && (
                        <div
                            className={
                                classes.most_recent_interactions_container
                            }
                        >
                            <div className={classes.recent_interaction}>
                                <div
                                    className={
                                        classes.recent_interaction_tooltip
                                    }
                                >
                                    {`${
                                        mostRecentInteraction.date
                                    }: ${mostRecentInteraction.text.substring(
                                        0,
                                        50
                                    )}...`}
                                </div>
                                <ToneIndicator
                                    tone={mostRecentInteraction.tone}
                                    label={mostRecentInteractionOwnerInitials}
                                />
                            </div>
                            <div className={classes.recent_interaction}>
                                <div
                                    className={
                                        classes.most_recent_interaction_label
                                    }
                                >
                                    {timeSince(
                                        mostRecentInteraction.date,
                                        "day"
                                    ) !== "td"
                                        ? timeSince(
                                              mostRecentInteraction.date,
                                              "day",
                                              "week"
                                          )
                                        : timeSince(
                                              mostRecentInteraction.created_at,
                                              "minute",
                                              "week"
                                          )}
                                </div>
                            </div>
                        </div>
                    )}
                    {tileData.season_interactions.length > 0 && (
                        <div className={classes.season_interactions_container}>
                            <div className={classes.season_interactions_count}>
                                {tileData.season_interactions.length}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const playerSort = (a, b, ignoreCount = false) => {
    if (
        (a.data?.season_interactions?.length ?? 0) === 0 ||
        (b.data?.season_interactions?.length ?? 0) === 0
    ) {
        return (a.data?.season_interactions?.length ?? 0) === 0
            ? 1
            : (b.data?.season_interactions?.length ?? 0) === 0
            ? -1
            : 0;
    }

    const countDiff =
        b.data.season_interactions.length - a.data.season_interactions.length;

    if (ignoreCount || countDiff === 0) {
        // If counts are equal, compare dates
        const dateA = new Date(a.data.season_interactions[0].date);
        const dateB = new Date(b.data.season_interactions[0].date);
        if (dateA.getTime() === dateB.getTime()) {
            // If dates are equal, compare created_at dates
            const createdAtA = new Date(a.data.season_interactions[0].created_at);
            const createdAtB = new Date(b.data.season_interactions[0].created_at);
            return createdAtB.getTime() - createdAtA.getTime(); // Most recent created_at first
        }

        return dateB - dateA; // Most recent first
    }

    return countDiff; // Largest count first
};

const TeamPlayerInteractions = ({
    teamId,
    tier,
    viewContextProp = {
        playerId: null,
        competitionId: null,
        metricContext: {
            stack: null,
            activeMetric: null,
        },
    },
    onViewContextChange,
    onMenuOpen,
}) => {
    const { isAuthenticated, checkPermission } = useAuth();

    const canViewPlayerInteractions = checkPermission(
        "core.can_view_player_interactions"
    );
    const canAddUpdatePlayerInteractions = checkPermission(
        "core.can_add_update_player_interactions"
    );
    const canManageOtherUsersPlayerInteractions =
        checkPermission("core.view_user") &&
        checkPermission("core.can_manage_other_users_interactions");

    const dispatch = useDispatch();

    const { playerConversationTypesValues } = usePlayerConversationTypes();

    const [selectedInteraction, setSelectedInteraction] = useState(null);
    const [isInteractionDialogOpen, setIsInteractionDialogOpen] =
        useState(false);

    const seasons = useSelector((state) => state.seasons.seasons);

    const currentSeasonId = useMemo(() => {
        // Find the season that has the latest start date, but no later than today.
        const today = new Date();
        return Object.values(seasons).reduce((currentSeasonId, season) => {
            if (
                season.start_date &&
                new Date(season.start_date) <= today &&
                (!currentSeasonId ||
                    season.start_date > seasons[currentSeasonId].start_date)
            ) {
                return season.id;
            }
            return currentSeasonId;
        }, null);
    }, [seasons]);

    const coaches = useSelector((state) => state.users.coaches);

    // Memoize the tier selection options, so that it doesn't
    // change on every render.
    const tierSelectionOptions = useMemo(() => {
        return ["NHL", "OFF_ROSTER"];
    }, []);

    const {
        setTeamId,
        setPlayersData,
        tieredPlayers,
        isLoading: tieredPlayersIsloading,
        tieredPlayersData,
    } = useTeamTieredPlayers(teamId, tierSelectionOptions, true, true);

    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];
    const ahlTeamId = team && team.affiliations[0];

    const [viewContext, setViewContext] = useState(viewContextProp);

    const [drilldownOpen, setDrilldownOpen] = useState(false);

    const teamIdRef = useRef(null);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (canViewPlayerInteractions && team) {
            if (teamIdRef.current !== team.id) {
                team_player_interactions_mp_track(
                    team,
                    tier,
                    "View NHL Team Player Interactions"
                );
                teamIdRef.current = team.id;
                setTeamId(team.id);

                // Show the loader for a short time to give the user feedback that
                // the page is loading, and hide initial render flicker.
                dispatch(uiActions.showLoader());
            }
        }
    }, [canViewPlayerInteractions, team, tier, setTeamId, dispatch]);

    // Clear loader after 500ms on initial mount. This assumes the teamId
    // never changes, otherwise we would need to do this process on every
    // team id change.
    useEffect(() => {
        const timeout = setTimeout(() => {
            setInitialLoad(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const depth_chart_config =
        teams_datastashes &&
        teams_datastashes[teamId] &&
        teams_datastashes[teamId].depth_chart_config;

    const teams_reserveListPlayerIds = useSelector(
        (state) => state.teams.reserveListPlayerIds[teamId]
    );

    const playerInteractions = useSelector(
        (state) => state.players.interactions
    );

    useEffect(() => {
        if (canViewPlayerInteractions) {
            dispatch(fetchSeasonPlayerInteractions(currentSeasonId));
        }
    }, [canViewPlayerInteractions, currentSeasonId, dispatch]);

    // Using a map to manage multiple WebSocket connections
    const [webSockets, setWebSockets] = useState(new Map());

    // Function to setup event listeners for a WebSocket
    const setupWebSocketEvents = useCallback(
        (websocket, entity, updateFunc) => {
            websocket.onopen = () => {
                console.log(`${entity} Updates WebSocket connected.`);
            };

            websocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log(
                    `${entity} WebSocket update received for update ${data.id}.`
                );

                dispatch(updateFunc([data]));
            };

            websocket.onclose = () => {
                console.log(`${entity} WebSocket disconnected.`);
            };
        },
        [dispatch]
    );

    const [shouldUpdateWebSockets, setShouldUpdateWebSockets] = useState(false);

    // Determine when to update the WebSockets
    useEffect(() => {
        if (isAuthenticated && canViewPlayerInteractions) {
            setShouldUpdateWebSockets(true);
        }
    }, [isAuthenticated, canViewPlayerInteractions]);

    // Effect for setting up WebSocket connections
    useEffect(() => {
        if (shouldUpdateWebSockets) {
            // Close existing connections
            webSockets.forEach((ws) => ws.close());
            const newWebSockets = new Map();

            // Create new connections
            const interactionsWebsocket = new WebSocket(
                process.env.REACT_APP_HABSHUB_WS_BASE_URL +
                    `playerinteractions/`
            );

            // Setup event listeners for each WebSocket
            setupWebSocketEvents(
                interactionsWebsocket,
                "Player Interactions",
                playersSlice.actions.updatePlayerInteractions
            );

            newWebSockets.set(1, interactionsWebsocket);

            setWebSockets(newWebSockets);
            setShouldUpdateWebSockets(false); // Reset the trigger
        }

        // Cleanup function to close all connections
        return () => {
            webSockets.forEach((ws) => ws.close());
        };
    }, [shouldUpdateWebSockets, setupWebSocketEvents, webSockets]);

    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, teams, dispatch]);

    useEffect(() => {
        if (teams && ahlTeamId && !teams[ahlTeamId]) {
            dispatch(fetchTeam(ahlTeamId));
            dispatch(uiActions.showLoader());
        }
    }, [ahlTeamId, teams, dispatch]);

    useEffect(() => {
        if (!depth_chart_config) {
            dispatch(fetchTeamDatastash(teamId, "depth_chart_config"));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, depth_chart_config, dispatch]);

    useEffect(() => {
        if (tieredPlayersIsloading) {
            dispatch(uiActions.showLoader());
        }
    }, [tieredPlayersIsloading, dispatch]);

    useEffect(() => {
        if (
            (!coaches || coaches.length === 0) &&
            canViewPlayerInteractions &&
            canManageOtherUsersPlayerInteractions
        ) {
            dispatch(fetchCoaches());
            dispatch(uiActions.showLoader());
        }
    }, [
        coaches,
        canViewPlayerInteractions,
        canManageOtherUsersPlayerInteractions,
        dispatch,
    ]);

    const allCompetitionIds = useMemo(() => {
        let ids = tieredPlayers
            .filter((tierData) => tierData.tier === "JCE")
            .reduce((accumulator, jceTierData) => {
                return jceTierData.data.reduce((accumulator, positionData) => {
                    return positionData.players.reduce(
                        (accumulator, playerData) => {
                            return accumulator.add(
                                playerData.player.targetCompetition
                            );
                        },
                        accumulator
                    );
                }, accumulator);
            }, new Set());
        ids.add(1);
        ids.add(2);
        return Array.from(ids).sort((a, b) => a - b);
    }, [tieredPlayers]);

    useEffect(() => {
        if (teams_reserveListPlayerIds) {
            const conversationsIds = playerConversationTypesValues.reduce(
                (conversationIds, interactionType) => {
                    conversationIds.push(interactionType.id);
                    return conversationIds;
                },
                []
            );

            setPlayersData(
                teams_reserveListPlayerIds.reduce((playersMap, playerId) => {
                    const mergedMap = allCompetitionIds.reduce(
                        (accumulator, competitionId) => {
                            accumulator[competitionId] = {
                                season_interactions: playerInteractions[
                                    playerId
                                ]?.filter(
                                    (interaction) =>
                                        interaction.season_id ===
                                            currentSeasonId &&
                                        conversationsIds.includes(
                                            interaction.interaction_type.id
                                        )
                                ),
                            };

                            return accumulator;
                        },
                        {}
                    );

                    playersMap[playerId] = mergedMap;
                    return playersMap;
                }, {})
            );
        } else {
            setPlayersData({});
        }
    }, [
        allCompetitionIds,
        teams_reserveListPlayerIds,
        playerInteractions,
        currentSeasonId,
        playerConversationTypesValues,
        setPlayersData,
    ]);

    const decoratedTieredPlayersData = useMemo(() => {
        return tieredPlayersData.map((tierData) => {
            return {
                ...tierData,
                label: subTiers[tierData.tier].label,
            };
        });
    }, [tieredPlayersData]);

    const [playersPool, setPlayersPool] = useState(null);

    useEffect(() => {
        const playersPool = tieredPlayersData.reduce(
            (accumulator, tierData) => {
                return tierData.data.reduce((accumulator, positionData) => {
                    return positionData.players.reduce(
                        (accumulator, playerData) => {
                            accumulator.push(playerData.player);
                            return accumulator;
                        },
                        accumulator
                    );
                }, accumulator);
            },
            []
        );
        setPlayersPool(playersPool);
    }, [tieredPlayersData]);

    useEffect(() => {
        if (
            !initialLoad &&
            team &&
            depth_chart_config &&
            !tieredPlayersIsloading &&
            (!canViewPlayerInteractions ||
                !canManageOtherUsersPlayerInteractions ||
                (coaches && coaches.length > 0))
        ) {
            dispatch(uiActions.hideLoader());
        }
    }, [
        initialLoad,
        team,
        depth_chart_config,
        tieredPlayersIsloading,
        canViewPlayerInteractions,
        canManageOtherUsersPlayerInteractions,
        coaches,
        dispatch,
    ]);

    const handlePlayerClicked = (playerId, competitionId) => {
        onViewContextChange &&
            onViewContextChange({
                ...EMPTY_VIEW_CONTEXT,
                playerId: playerId,
                competitionId: competitionId,
                metricContext: {
                    stack: null,
                    activeMetric: null,
                },
            });
        setViewContext({
            ...EMPTY_VIEW_CONTEXT,
            playerId: playerId,
            competitionId: competitionId,
            metricContext: {
                stack: null,
                activeMetric: null,
            },
        });
        setDrilldownOpen(true);
    };

    const handleSortOrderChange = (sortOrder) => {
        setSortOrder(sortOrder);
        team_player_interactions_mp_track(
            team,
            tier,
            "Set Team Player Interactions Sort Order",
            {
                sort_order: sortOrder.id,
            }
        );
    };

    const onPlayerIndexDrilldownClosed = () => {
        // Wait .3s, then set viewContext to null
        setDrilldownOpen(false);
        setTimeout(() => {
            onViewContextChange && onViewContextChange(EMPTY_VIEW_CONTEXT);
            setViewContext(EMPTY_VIEW_CONTEXT);
        }, 300);
    };

    useEffect(() => {
        if (viewContextProp) {
            setViewContext(viewContextProp);
        }
        if (viewContextProp && viewContextProp.playerId) {
            setDrilldownOpen(true);
        }
    }, [viewContextProp]);

    const sortOptions = [
        {
            id: "count",
            label: "Most Often",
        },
        {
            id: "recent",
            label: "Most Recent",
        },
    ];

    const [sortOrder, setSortOrder] = useState(sortOptions[0]);

    const [sidePanelWidth, setSidePanelWidth] = useState(null);
    const [mainContentWidth, setMainContentWidth] = useState(null);
    const [mainContentHeight, setMainContentHeight] = useState(null);

    return (
        // Setting a key on the AppPage component forces it to re-mount when
        // the teamId changes, which is necessary trigger the transition
        // effects.
        <AppPage key={"TeamPlayerInteractions_" + teamId}>
            <TeamPlayerInteractionsLegend zIndex={4} />
            <PlayerInteractionDialog
                playersPool={playersPool}
                usersPool={canManageOtherUsersPlayerInteractions && coaches}
                interaction={selectedInteraction}
                isOpen={isInteractionDialogOpen}
                onCloseHandler={() => {
                    setIsInteractionDialogOpen(false);
                }}
                interactionTypes={playerConversationTypesValues}
            />
            <PlayerInteractionsPanel
                playerId={viewContext.playerId}
                competitionId={viewContext.competitionId}
                isOpen={drilldownOpen}
                onCloseHandler={onPlayerIndexDrilldownClosed}
                targetWidthHandler={setSidePanelWidth}
            />
            <CompressiblePanel
                compressWidth={sidePanelWidth}
                onWidthChange={setMainContentWidth}
                onHeightChange={setMainContentHeight}
            >
                <OrgHeader
                    fullTitle={
                        (team ? team.location + " " + team.name : "") +
                        " Player Interactions"
                    }
                    mediumTitle={
                        (team ? team.location : "") + " Player Interactions"
                    }
                    shortTitle={"Player Interactions"}
                    onOpen={onMenuOpen}
                    bgColor={team ? team.primary_colour : "#000"}
                    imgUrl={team ? team.alt_logo_url : ""}
                    imgPosition={
                        depth_chart_config
                            ? depth_chart_config.header_object_position
                            : "50% 50%"
                    }
                    parentWidth={mainContentWidth}
                    parentHeight={mainContentHeight}
                />
                <MainContent>
                    {!canViewPlayerInteractions && (
                        <div className={classes.no_access}>
                            You do not have access to view player development.
                        </div>
                    )}
                    {canViewPlayerInteractions &&
                        (decoratedTieredPlayersData.length > 0 ? (
                            <>
                                <NavigationBar>
                                    <NavigationSetting>
                                        <div
                                            className={
                                                classes.add_interaction_button_container
                                            }
                                        >
                                            {canAddUpdatePlayerInteractions && (
                                                <IconButton
                                                    label="Add Interaction"
                                                    materialIcon={
                                                        "calendar_add_on"
                                                    }
                                                    onClick={
                                                        canAddUpdatePlayerInteractions &&
                                                        (() => {
                                                            setSelectedInteraction(
                                                                null
                                                            );
                                                            setIsInteractionDialogOpen(
                                                                true
                                                            );
                                                            player_interactions_mp_track(
                                                                "Open Add Interaction Dialog"
                                                            );
                                                        })
                                                    }
                                                />
                                            )}
                                        </div>
                                    </NavigationSetting>
                                    <NavigationSetting>
                                        <div className={classes.setting_label}>
                                            Sort
                                        </div>
                                        <OptionRadioBar
                                            options={sortOptions}
                                            selectedOption={sortOrder}
                                            onSelect={handleSortOrderChange}
                                            OptionComponent={({
                                                optionValue,
                                            }) => (
                                                <div
                                                    className={
                                                        classes.sort_option
                                                    }
                                                >
                                                    {optionValue?.label}
                                                </div>
                                            )}
                                        />
                                    </NavigationSetting>
                                </NavigationBar>
                                <TieredTeamPlayers
                                    teamId={teamId}
                                    tieredPlayersData={
                                        decoratedTieredPlayersData
                                    }
                                    playerSort={
                                        sortOrder.id === "count"
                                            ? (a, b) => playerSort(a, b, false)
                                            : (a, b) => playerSort(a, b, true)
                                    }
                                    mainContentWidth={mainContentWidth}
                                    TileComponent={PlayerInteractionsTile}
                                    onPlayerClick={handlePlayerClicked}
                                />
                            </>
                        ) : (
                            <div className={classes.no_data}>
                                No player data available.
                            </div>
                        ))}
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default TeamPlayerInteractions;
