import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchPlayerDevelopmentPriorityOutcomes,
    fetchPlayerInteractionTypes,
} from "../store/playerdevelopmentoptions-actions";

export function usePlayerDevelopmentPriorityOutcomes() {
    const dispatch = useDispatch();

    const playerDevelopmentPriorityOutcomes = useSelector(
        (state) => state.playerdevelopmentoptions.developmentpriorityoutcomes
    );

    const playerDevelopmentPriorityOutcomesValues = useMemo(
        () =>
            Object.values(playerDevelopmentPriorityOutcomes).sort(
                (a, b) => a.order - b.order
            ),
        [playerDevelopmentPriorityOutcomes]
    );

    useEffect(() => {
        if (Object.keys(playerDevelopmentPriorityOutcomes).length === 0) {
            dispatch(fetchPlayerDevelopmentPriorityOutcomes());
        }
    }, [dispatch, playerDevelopmentPriorityOutcomes]);
    return {
        playerDevelopmentPriorityOutcomes,
        playerDevelopmentPriorityOutcomesValues,
    };
}

export function usePlayerConversationTypes() {
    const dispatch = useDispatch();

    const playerConversationTypes = useSelector(
        (state) => state.playerdevelopmentoptions.conversationtypes
    );

    const playerConversationTypesValues = useMemo(
        () =>
            Object.values(playerConversationTypes).sort(
                (a, b) => a.order - b.order
            ),
        [playerConversationTypes]
    );

    useEffect(() => {
        if (Object.keys(playerConversationTypes).length === 0) {
            dispatch(fetchPlayerInteractionTypes());
        }
    }, [dispatch, playerConversationTypes]);

    return {
        playerConversationTypes,
        playerConversationTypesValues,
    };
}

export function usePlayerInteractionTypes() {
    const dispatch = useDispatch();

    const playerInteractionTypes = useSelector(
        (state) => state.playerdevelopmentoptions.interactiontypes
    );

    const playerInteractionTypesValues = useMemo(
        () =>
            Object.values(playerInteractionTypes).sort(
                (a, b) => a.order - b.order
            ),
        [playerInteractionTypes]
    );

    useEffect(() => {
        if (Object.keys(playerInteractionTypes).length === 0) {
            dispatch(fetchPlayerInteractionTypes());
        }
    }, [dispatch, playerInteractionTypes]);

    return {
        playerInteractionTypes,
        playerInteractionTypesValues,
    };
}

export function usePlayerDevelopmentSessionTypes() {
    const dispatch = useDispatch();

    const playerDevelopmentSessionTypes = useSelector(
        (state) => state.playerdevelopmentoptions.sessiontypes
    );

    const playerDevelopmentSessionTypesValues = useMemo(
        () =>
            Object.values(playerDevelopmentSessionTypes).sort(
                (a, b) => a.order - b.order
            ),
        [playerDevelopmentSessionTypes]
    );

    useEffect(() => {
        if (Object.keys(playerDevelopmentSessionTypes).length === 0) {
            dispatch(fetchPlayerInteractionTypes());
        }
    }, [dispatch, playerDevelopmentSessionTypes]);

    return {
        playerDevelopmentSessionTypes,
        playerDevelopmentSessionTypesValues,
    };
}
