import classes from "./PlayerInteractionsPanel.module.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SlideInPanel from "../../layout/SlideInPanel";
import PlayerHeader from "../player-header/PlayerHeader";
import OverheadTitleSection from "../../layout/OverheadTitleSection";
import { fetchPlayerInteractions } from "../../store/players-actions";
import IconButton from "../../controls/buttons/IconButton";
import { ToneIndicator } from "../indicators";
import useAuth from "../../hooks/use-auth";
import { shortDateString } from "../../utils";
import {
    PlayerNoteHeader,
    PlayerNoteText,
    PlayerNoteOwner,
} from "../PlayerNote";
import { usePlayerConversationTypes } from "../../hooks/enum-helpers";

import {
    PlayerInteractionDialog,
    player_interactions_mp_track,
} from "../PlayerInteractionsDialogs";

const PlayerInteraction = ({ interaction, user, onEditClick, player }) => {
    const { checkPermission } = useAuth();

    const canManageOtherUsersPlayerInteractions = checkPermission(
        "core.can_manage_other_users_interactions"
    );

    return (
        <div key={interaction.id} className={classes.interaction_container}>
            <PlayerNoteHeader
                elements={[
                    shortDateString(interaction.date),
                    interaction.interaction_type.label,
                    `${interaction.duration_minutes} min`,
                    <ToneIndicator tone={interaction.tone} />,
                    interaction.was_player_instigated && "(player instigated)",
                ].filter(Boolean)}
            />
            <PlayerNoteText
                note={interaction}
                user={user}
                editClickHandler={() => onEditClick(interaction)}
                forceEditable={canManageOtherUsersPlayerInteractions}
            />
            <PlayerNoteOwner note={interaction} />
        </div>
    );
};

const NoContentMessage = ({ canViewPlayerInteractions }) => (
    <div className={classes.empty_container}>
        <div className={classes.no_content}>
            <div className={classes.no_content_text}>
                {canViewPlayerInteractions
                    ? "No interactions"
                    : "You do not have permission to view player interactions"}
            </div>
        </div>
    </div>
);

function PlayerInteractionsList({
    canViewPlayerInteractions,
    playerInteractions,
    user,
    onEditInteraction,
    player,
}) {
    if (!canViewPlayerInteractions) {
        return <NoContentMessage canViewPlayerInteractions={false} />;
    }

    if (!playerInteractions || playerInteractions.length === 0) {
        return <NoContentMessage canViewPlayerInteractions={true} />;
    }

    return (
        <div className={classes.development_sessions_container}>
            {playerInteractions.map((interaction) => (
                <PlayerInteraction
                    key={interaction.id}
                    interaction={interaction}
                    user={user}
                    onEditClick={onEditInteraction}
                    player={player}
                />
            ))}
        </div>
    );
}

function PlayerInteractionsPanel({
    playerId,
    competitionId,
    isOpen,
    onCloseHandler,
    targetWidthHandler,
}) {
    const dispatch = useDispatch();
    const { user, checkPermission } = useAuth();
    const { playerConversationTypesValues } = usePlayerConversationTypes();
    const conversationsIds = playerConversationTypesValues.reduce(
        (conversationIds, interactionType) => {
            conversationIds.push(interactionType.id);
            return conversationIds;
        },
        []
    );

    const canViewPlayerInteractions = checkPermission(
        "core.can_view_player_interactions"
    );
    const canAddUpdatePlayerInteractions = checkPermission(
        "core.can_add_update_player_interactions"
    );
    const canManageOtherUsersPlayerInteractions =
        checkPermission("core.view_user") &&
        checkPermission("core.can_manage_other_users_interactions");

    const contentWidth = 480;
    const contentPaddingWidth = 20;
    const targetWidth = contentWidth + contentPaddingWidth;

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );

    const [confirmedPlayerId, setConfirmedPlayerId] = useState(null);
    const [selectedInteraction, setSelectedInteraction] = useState(null);
    const [isInteractionDialogOpen, setIsInteractionDialogOpen] =
        useState(false);
    const [playerChangeWait, setPlayerChangeWait] = useState(false);
    const [isCoachEditable, setIsCoachEditable] = useState(false);

    const player = useSelector(
        (state) => state.players.players[confirmedPlayerId]
    );
    const playerInteractions = useSelector(
        (state) => state.players.interactions[confirmedPlayerId]
    );

    const playerConversations =
        playerInteractions &&
        playerInteractions.filter((interaction) =>
            conversationsIds.includes(interaction.interaction_type.id)
        );

    const coaches = useSelector((state) => state.users.coaches);

    useEffect(() => {
        if (isOpen) {
            targetWidthHandler && targetWidthHandler(targetWidth);
        } else {
            targetWidthHandler && targetWidthHandler(null);
        }
    }, [isOpen, targetWidth, targetWidthHandler]);

    useEffect(() => {
        setPlayerChangeWait(true);
        dispatch(fetchPlayerInteractions(playerId));
        setTimeout(() => {
            setConfirmedPlayerId(playerId);
            setPlayerChangeWait(false);
        }, 175);
    }, [playerId, dispatch]);

    useEffect(() => {
        if (player) {
            player_interactions_mp_track("View Player Interactions", player);
        }
    }, [player]);

    const onClose = () => {
        player_interactions_mp_track(
            "Player Interactions Panel Closed",
            player
        );
        onCloseHandler();
    };

    const handleEditInteraction = (interaction) => {
        setSelectedInteraction(interaction);
        setIsCoachEditable(false);
        setIsInteractionDialogOpen(true);
        player_interactions_mp_track("Open Edit Interaction Dialog", player, {
            interaction_id: interaction.id,
        });
    };

    const handleAddInteraction = (interaction) => {
        setSelectedInteraction(null);
        setIsCoachEditable(true);
        setIsInteractionDialogOpen(true);
        player_interactions_mp_track("Open Add Interaction Dialog", player, {
            interaction_id: interaction.id,
        });
    };

    const isLoading = playerChangeWait;

    return (
        <>
            <PlayerInteractionDialog
                player={player}
                usersPool={
                    canManageOtherUsersPlayerInteractions &&
                    isCoachEditable &&
                    coaches
                }
                interaction={selectedInteraction}
                isOpen={isInteractionDialogOpen}
                onCloseHandler={() => setIsInteractionDialogOpen(false)}
                interactionTypes={playerConversationTypesValues}
            />
            <SlideInPanel
                isOpen={isOpen}
                isLoading={isLoading}
                onClose={onClose}
                targetWidth={targetWidth}
                side="right"
            >
                {confirmedPlayerId && (
                    <div className={classes.slide_in_content}>
                        <div className={classes.player_header_container}>
                            <PlayerHeader
                                player={player}
                                narrowLayout={targetWidth < 700}
                                showContractDetails={false}
                                performanceViewContext={{
                                    competitionId: competitionId,
                                    seasonId: currentSeasonId,
                                }}
                            />
                        </div>
                        <OverheadTitleSection title="Interactions" />
                        {canAddUpdatePlayerInteractions && (
                            <div className={classes.control_group}>
                                <IconButton
                                    label="Add Interaction"
                                    materialIcon={"calendar_add_on"}
                                    onClick={handleAddInteraction}
                                />
                            </div>
                        )}
                        <PlayerInteractionsList
                            canViewPlayerInteractions={
                                canViewPlayerInteractions
                            }
                            playerInteractions={playerConversations}
                            user={user}
                            onEditInteraction={handleEditInteraction}
                            player={player}
                        />
                    </div>
                )}
            </SlideInPanel>
        </>
    );
}

export default PlayerInteractionsPanel;
