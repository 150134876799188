import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import NavigationBar, { NavigationSetting } from "../controls/NavigationBar";
import OptionDropdown from "../controls/OptionDropdown";
import OptionRadioBar from "../controls/OptionRadioBar";
import PlayerboardPage from "../app-pages/PlayerboardPage";
import useAuth from "../hooks/use-auth";
import { mp_track } from "../mixpanel";
import classes from "./ScoutingBoards.module.css";

const scouting_board_mp_track = (
    event,
    board_name,
    competition,
    properties = null
) => {
    properties = {
        ...properties,
        board_name: board_name,
        competition_id: competition?.id,
        competition_name: competition?.display_name,
    };

    mp_track(event, properties);
};

const scoutingBoardDefinitions = [
    {
        name: "Upcoming UFA",
        playerboard_id: 7,
        competition_id: 1,
        slug: "ufa",
    },
    {
        name: "Upcoming RFA",
        playerboard_id: 8,
        competition_id: 1,
        slug: "rfa",
    },
    {
        name: "Other UFA",
        playerboard_id: 86,
        competition_id: 1,
        slug: "other-ufa",
    },
    {
        name: "AHL Contracts",
        playerboard_id: 9,
        competition_id: 2,
        slug: "ahl",
    },
    {
        name: "U24 F6/D4",
        playerboard_id: 5,
        competition_id: 1,
        slug: "nhl",
    },
    {
        name: "U24 F6/D4 Non-NHL",
        playerboard_id: 6,
        competition_id: null,
        slug: "non-nhl",
    },
    {
        name: "U27 NHL Blue+ OGPs Forwards",
        playerboard_id: 10,
        competition_id: 1,
        slug: "nhl-ogp-f",
    },
    {
        name: "U24 AHL Blue+ OGPs Forwards",
        playerboard_id: 11,
        competition_id: 2,
        slug: "ahl-ogp-f",
    },
    {
        name: "U27 NHL 4/5 Pillars Forwards",
        playerboard_id: 12,
        competition_id: 1,
        slug: "nhl-pillars-f",
    },
    {
        name: "U24 AHL 4/5 Pillars Forwards",
        playerboard_id: 13,
        competition_id: 2,
        slug: "ahl-pillars-f",
    },
];

const ScoutingBoardGroups = [
    {
        name: "Free Agents",
        boards: [
            {
                slug: "ufa",
                selection_label: "2025 UFA",
            },
            {
                slug: "rfa",
                selection_label: "2025 RFA",
            },
            {
                slug: "ahl",
                selection_label: "AHL Contracts",
            },
            {
                slug: "other-ufa",
                selection_label: "Other UFA",
            },
        ],
    },
    {
        name: "F6/D4 Projections",
        boards: [
            {
                slug: "nhl",
                selection_label: "NHL",
            },
            {
                slug: "non-nhl",
                selection_label: "Non-NHL",
            },
        ],
    },
    {
        name: "High OGPs Forwards",
        boards: [
            {
                slug: "nhl-ogp-f",
                selection_label: "NHL",
            },
            {
                slug: "ahl-ogp-f",
                selection_label: "AHL",
            },
        ],
    },
    {
        name: "4+ Pillars Forwards",
        boards: [
            {
                slug: "nhl-pillars-f",
                selection_label: "NHL",
            },
            {
                slug: "ahl-pillars-f",
                selection_label: "AHL",
            },
        ],
    },
];

const BoardSelector = ({ groups, selectedBoardSlug, onSelectBoard }) => {
    const [selectedGroupSlug, setSelectedGroupSlug] = useState(
        groups.find((group) =>
            group.boards.some((board) => board.slug === selectedBoardSlug)
        ).boards[0].slug
    );
    const [selectedGroup, setSelectedGroup] = useState(
        groups.find((group) =>
            group.boards.some((board) => board.slug === selectedBoardSlug)
        )
    );

    useEffect(() => {
        const group = groups.find((group) =>
            group.boards.some((board) => board.slug === selectedBoardSlug)
        );
        if (group) {
            setSelectedGroupSlug(group.boards[0].slug);
            if (!selectedGroup || selectedGroup.name !== group.name) {
                setSelectedGroup(group);
            }
        }
    }, [selectedBoardSlug, selectedGroup, groups]);

    const groupOptions = useMemo(() => {
        return groups.map((group) => ({
            id: group.boards[0].slug,
            label: group.name,
        }));
    }, [groups]);

    const boardOptions = selectedGroup
        ? selectedGroup.boards.map((board) => ({
              id: board.slug,
              label: board.selection_label,
          }))
        : [];

    const handleGroupSelect = (option) => {
        const newGroup = groups.find(
            (group) => group.boards[0].slug === option.id
        );
        setSelectedGroupSlug(option.id);
        setSelectedGroup(newGroup);
        onSelectBoard(newGroup.boards[0].slug);
    };

    const handleBoardSelect = (option) => {
        onSelectBoard(option.id);
    };

    return (
        <NavigationSetting label="Board Selection">
            <div className={classes.board_selector_container}>
                <div className={classes.group_selector}>
                    <OptionDropdown
                        options={groupOptions}
                        selectedOption={groupOptions.find(
                            (option) => option.id === selectedGroupSlug
                        )}
                        onSelect={handleGroupSelect}
                        OptionComponent={({ optionValue }) => (
                            <div className={classes.board_option}>
                                {optionValue?.label}
                            </div>
                        )}
                    />
                </div>
                <OptionRadioBar
                    options={boardOptions}
                    selectedOption={boardOptions.find(
                        (option) => option.id === selectedBoardSlug
                    )}
                    onSelect={handleBoardSelect}
                    OptionComponent={({ optionValue }) => (
                        <div className={classes.board_option}>
                            {optionValue?.label}
                        </div>
                    )}
                />
            </div>
        </NavigationSetting>
    );
};

const ScoutingBoards = ({
    initialBoardSlug,
    onMenuOpen,
    viewContextProp,
    onViewContextChange,
}) => {
    const { checkPermission } = useAuth();

    const canAccessScoutingBoards = checkPermission(
        "core.can_access_scouting_boards"
    );

    const navigate = useNavigate();
    const competitions = useSelector(
        (state) => state.competitions.competitions
    );

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );

    // State to keep track of the selected board
    const [selectedBoard, setSelectedBoard] = useState(null);

    // Effect to set initial board if not provided
    useEffect(() => {
        if (!selectedBoard) {
            const initialBoard = Object.values(scoutingBoardDefinitions).find(
                (board) => board.slug === initialBoardSlug
            );
            if (initialBoard) {
                setSelectedBoard(initialBoard);
            }
        }
    }, [selectedBoard, initialBoardSlug]);

    // Handler for board selection
    const handleSelectBoard = useCallback(
        (scoutingBoardSlug) => {
            const scoutingBoard = scoutingBoardDefinitions.find(
                (board) => board.slug === scoutingBoardSlug
            );
            setSelectedBoard(scoutingBoard);
            scouting_board_mp_track(
                "Scouting Board Selected",
                competitions[scoutingBoard.competition_id],
                { board_name: scoutingBoard.name }
            );
            // Update the navigation URL, and create a new entry in the browser history
            navigate(`/app/scoutingboards/${scoutingBoard.slug}`);
        },
        [competitions, navigate]
    );

    const playerboardId = selectedBoard?.playerboard_id;

    // If the user does not have permission to access scouting boards, or no board is selected, return null
    if (!canAccessScoutingBoards || !selectedBoard) return null;

    return (
        playerboardId && (
            <PlayerboardPage
                boardMode={"Trade"}
                playerboardId={playerboardId}
                viewContextProp={viewContextProp}
                onViewContextChange={onViewContextChange}
                onMenuOpen={onMenuOpen}
                boardCompetitionId={selectedBoard.competition_id}
                bgColor={"var(--ch-blue)"}
                imgUrl={"/static/static/dashboards/scouting_logo.svg"}
                imgPosition={"50% 50%"}
                metricsSeason={currentSeasonId}
                showOwningTeamOverlay={true}
                NavigationWidget={() => (
                    <NavigationBar>
                        <BoardSelector
                            boards={scoutingBoardDefinitions}
                            groups={ScoutingBoardGroups}
                            selectedBoardSlug={selectedBoard.slug}
                            onSelectBoard={handleSelectBoard}
                        />
                    </NavigationBar>
                )}
                showFullPageLoader={false}
            />
        )
    );
};

export default ScoutingBoards;
