import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchData } from "../utils";
import { uiActions } from "../store/ui-slice";

export const usePlayerInteractionTextAnalysis = () => {
    const dispatch = useDispatch();

    const textAnalysis = useCallback(
        async (text) => {
            const postData = {
                text: text,
            };

            try {
                const response = await fetchData(
                    `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerinteractions/textanalysis/`,
                    "Failed to analyze interaction text!",
                    "POST",
                    postData
                );

                return response;
            } catch (error) {
                dispatch(
                    uiActions.showNotification({
                        status: "error",
                        title: "Error!",
                        message: "Performing interaction text analysis failed!",
                    })
                );
                throw error;
            }
        },
        [dispatch]
    );

    return { textAnalysis };
};
