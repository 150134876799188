import { createSlice } from "@reduxjs/toolkit";

const playerDevelopmentOptionsSlice = createSlice({
    name: "playerdevelopmentoptions",
    initialState: {
        developmentpriorityoutcomes: {},
        interactiontypes: {},
        sessiontypes: {},
        conversationtypes: {},
    },
    reducers: {
        updatePlayerDevelopmentPriorityOutcomes(state, action) {
            const developmentPriorityOutcomes = action.payload;

            developmentPriorityOutcomes.forEach(
                (developmentPriorityOutcome) => {
                    state.developmentpriorityoutcomes[
                        developmentPriorityOutcome.id
                    ] = developmentPriorityOutcome;
                }
            );
        },
        updatePlayerInteractionTypes(state, action) {
            const interactionTypes = action.payload;

            interactionTypes.forEach((interactionType) => {
                state.interactiontypes[interactionType.id] = interactionType;
                if (interactionType.is_development_session) {
                    state.sessiontypes[interactionType.id] = interactionType;
                }
                if (interactionType.is_conversation) {
                    state.conversationtypes[interactionType.id] =
                        interactionType;
                }
            });
        },
    },
});

export const playerDevelopmentOptionsActions =
    playerDevelopmentOptionsSlice.actions;

export default playerDevelopmentOptionsSlice;
